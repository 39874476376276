import { createStore } from 'vuex';
import { vuexLocal } from '@/localstorage'; // Adjust the import path as necessary

const language_code_to_name = {
  am: "Amharic",
  ar: "Arabic",
  az: "Azerbaijani",
  ber: "Berber",
  bn: "Bangla",
  cs: "Czech",
  de: "German",
  dv: "Maldivian",
  en: "English",
  es: "Spanish",
  fa: "Persian",
  fr: "French",
  ha: "Hausa",
  hi: "Hindi",
  id: "Indonesian",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  ku: "Kurdish",
  ml: "Malayalam",
  nl: "Dutch",
  no: "Norwegian",
  pl: "Polish",
  ps: "Pashto, Pushto",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  sd: "Sindhi",
  so: "Somali",
  sq: "Albanian",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  tg: "Tajik",
  th: "Thai",
  tr: "Turkish",
  tt: "Tatar",
  ug: "Uighur",
  ur: "Urdu",
  uz: "Uzbek"
};

export default createStore({
  state: {
    user: null,
    user_avatar: null,
    token: null,
    isAuthenticated: false,
    edition_identifier: "en.ahmedali",
    recent_chapters: [],
    recent_surahs: [],
    language_code_to_name: language_code_to_name
  },
  getters: {
    getUser: state => state.user,
    getUserAvatar: state => state.user_avatar,
    getToken: state => state.token,
    isAuthenticated: state => state.isAuthenticated,
    getEdition: state => state.edition_identifier,
    getRSurahs: state => state.recent_surahs,
    getRChapters: state => state.recent_chapters,
    getLanguageFullName: state => state.language_code_to_name,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_AVATAR(state, data) {
      state.user_avatar = data;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_EDITIONAL(state, data) {
      state.edition_identifier = data;
    },
    SET_R_CHAPTERS(state, data) {
      state.recent_chapters.unshift(data);
    },
    SET_R_SURAHS(state, data) {
      state.recent_surahs.unshift(data);
    },
    CLEAR_R_CHAPTERS(state) {
      state.recent_chapters = [];
    },
    CLEAR_R_SURAHS(state) {
      state.recent_surahs = [];
    },
  },
  actions: {
    logout({ commit }) {
      commit('SET_USER', null);
      commit('SET_USER_AVATAR', null);
      commit('SET_TOKEN', null);
      commit('SET_AUTHENTICATED', false);
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
