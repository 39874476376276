<template>
  
  <div v-if="loading" class="mediaplayer">
    <div class="container text-white">
      Please wait
    </div>
  </div>
  <div v-else>
    <div class="mediaplayer">
      <div class="d-flex align-items-center container" v-if="displayPlayer">
        <div>
          <button @click="play" v-if="playing == false" class="text-white d-block p-0 border-0 h2 mb-0 bg-transparent"><i class="bi bi-play"></i></button>
          <button @click="pause" v-else class="text-white d-block p-0 border-0 h2 mb-0 bg-transparent"><i class="bi bi-pause"></i></button>
        </div>
        <div class="flex-fill px-3">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progress + '%' }"></div>
          </div>        
        </div>
        <div>
          <div class="time-display text-end text-white small">
            {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      textToSpeak: "",
      language: "",
      speechSynthesis: window.speechSynthesis,
      audioElement: null,
      displayPlayer: false,
      loading: false,
      playing: false,
      progress: 0,
      currentTime: 0,
      duration: 0,
      audioUrl:''
    };
  },
  methods: {
    play() {
      if (this.audioElement) {
        this.audioElement.play();
      }
    },
    pause() {
      if (this.audioElement) {
        this.audioElement.pause();
      }
    },
    async convertTextToSpeech() {
      this.pause()
      this.loading = true;
      try {

        if(this.audioUrl == "")
        {
          const formData = axios.toFormData({
              'text_input' : this.textToSpeak,
              'language_code' : this.language,
          })
          const response = await axios.post(`${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/text-to-speach/`, formData);
          console.log(response.status)
          if(response.status == 200)
          {
            this.loading = false;
            this.displayPlayer = true
            const encodedAudio = await response.data;
            if (this.audioElement) {
              this.audioElement.pause(); // Stop the previous audio if it was playing
            }
            const audio = new Audio(`data:audio/mp3;base64,${encodedAudio.audio_content}`);
            audio.playbackRate = 0.9; // Set the playback rate
            audio.play();        
            this.audioElement = audio; // Save reference to the current audio element
            this.audioElement.addEventListener('playing', () => {
              this.playing = true;
              this.duration = this.audioElement.duration;
              this.updateProgressBar();
            });
            this.audioElement.addEventListener('pause', () => {
              this.playing = false;
            });
            this.audioElement.addEventListener('timeupdate', () => {
              this.currentTime = this.audioElement.currentTime;
              this.updateProgressBar();
            });
          }
          else
          {
            this.loading = false;
            console.log("Voice is not available")
            this.$swal({
              icon: 'error',
              title: 'Apologies',
              text: 'Voice is not available',
            });
          }
        }
        else
        {
          this.loading = false;
          this.displayPlayer = true
          const audio = new Audio(this.audioUrl);
          audio.playbackRate = 0.9; // Set the playback rate
          audio.play();        
          this.audioElement = audio; // Save reference to the current audio element
          this.audioElement.addEventListener('playing', () => {
            this.playing = true;
            this.duration = this.audioElement.duration;
            this.updateProgressBar();
          });
          this.audioElement.addEventListener('pause', () => {
            this.playing = false;
          });
          this.audioElement.addEventListener('timeupdate', () => {
            this.currentTime = this.audioElement.currentTime;
            this.updateProgressBar();
          });
        }
      } catch (error) {
        this.loading = false;
        // console.error('Error fetching speech data:', error);
        console.log("Voice is not available")
        this.$swal({
          icon: 'error',
          title: 'Apologies',
          text: 'Voice is not available',
        });
      }
    },
    
    togglePlayback() {
      if (this.audioElement) {
        if (this.audioElement.paused) {
          this.audioElement.play();
        } else {
          this.audioElement.pause();
        }
      }
    },
    updateProgressBar() {
      if (this.audioElement) {
        const currentTime = this.audioElement.currentTime;
        const duration = this.audioElement.duration;
        this.progress = (currentTime / duration) * 100;
        console.log(Math.round(duration))
        console.log(Math.round(currentTime))
      }
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
    async playSound(response) {
      try {
        // const response = await fetch('your_audio_file.mp3');
        const audioBlob = await response.blob();
        const audioUrl = URL.createObjectURL(audioBlob);
        
        const audio = new Audio(audioUrl);
        audio.play();
      } catch (error) {
        console.error('Error fetching or playing audio:', error);
      }
    },
    async getAudio()
    {
      try {
        // const response = await fetch('http://your-django-server/text-to-speech/');
        const response = await fetch(`${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/text-to-speach/`);
        
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const audioBlob = await response.blob();
        const audioUrl = URL.createObjectURL(audioBlob);

        // Now you can use audioUrl to play the audio
        // For example, create an audio element and play it
        const audio = new Audio(audioUrl);
        audio.play();
      } catch (error) {
        console.error('There was a problem getting the audio:', error);
      }
    },
    async readText(text,language, audio_url = "")
    {
      this.textToSpeak = text
      this.language = language
      this.audioUrl = audio_url

      // setTimeout( function() {   
        this.stopSpeaking();   
        if (this.textToSpeak) {
          this.convertTextToSpeech()
        }
      // }, 1000);
    },
    speak() {
      if (this.textToSpeak && this.speechSynthesis) {
        const utterance = new SpeechSynthesisUtterance(this.textToSpeak);

        // Google Text-to-Speech API requires you to set the voice using the "Google" service
        // utterance.voiceURI = "Google UK English Female"; // Change this to the desired voice

        this.speechSynthesis.speak(utterance);
      }
    },
    stopSpeaking() {
      this.speechSynthesis.cancel();
    },
  },
};
</script>

<style scoped>
.mediaplayer
{
  background: #CE8610;
  padding: 2px 10px;
  text-align: center;
}
.progress-bar-container {
  width: 100%;
  height: 5px;
  background-color: #fff;
}

.progress-bar {
  height: 100%;
  /* background-color: #4caf50; */
  background: #F8BE1B;
}
</style>