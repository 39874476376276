<template>
          <form autocomplete="off" autosave="off" @submit.prevent="loginAction()">
          
              
                <div class="mb-3">
                  <label for="username" class="form-label">Mobile / Email*</label>
                  <input type="text" class="form-control form-control-lg " id="username" placeholder="" v-model="loginUser.username">
                  <span class="text-danger w-100 text-end" v-if="loginUser.login_error && v$.loginUser.username.$error"> {{ v$.loginUser.username.$errors[0].$message }} </span>
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password*</label>
                  <div class="position-relative mt-3">
                    <input :type="showPassword ? 'text' : 'password'" class="form-control form-control-lg" id="password" placeholder="" v-model="loginUser.password">
              <button @click="togglePassword" type="button"
                class="btn btn-link position-absolute end-0 top-50 translate-middle-y">
                <i class="text-secondary" :class="showPasswordtwo ? 'bi bi-eye-slash' : 'bi bi-eye-fill'"></i>
              </button>
            </div>
                  <span class="text-danger w-100 text-end" v-if="loginUser.login_error && v$.loginUser.password.$error"> {{ v$.loginUser.password.$errors[0].$message }} </span>
                </div>
                <div class="row mb-3">
                  <div class="col-6 ">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridCheck" v-model="loginUser.remember_me">
                      <label class="form-check-label" for="gridCheck">
                        Remember me
                      </label>
                    </div>

                  </div>
                  <div class="col-6 text-end">
                    <div style="cursor: pointer;" class="text-end" @click="forgot">Forgot password ?</div>
                    <!-- <router-link class="text-link text-decoration-none" :to="{ name:'RegisterView' }">Forgot password</router-link> -->
                  </div>
                </div>
                
            
              <div class="d-grid gap-3 text-center" v-if="!loading">
                  <button class="button py-2 px-5 mt-3" type="submit">Sign in</button>
                  <!-- <button class="theme-button-outline" type="button"><img src="../../assets/google.svg" height="20"> Sign in with Google</button> -->
                  <GoogleAuth></GoogleAuth>
              </div>
              <div class="d-grid gap-3 text-center" v-else>
                  <button disabled class="button py-2 px-5 mt-3 disabled" type="button">Please wait</button>
                  <GoogleAuth></GoogleAuth>
              </div>
           
          </form>
        
</template>
<script>
// import { mapState } from 'vuex';
import swal from 'sweetalert';
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import axios from 'axios';

import GoogleAuth from "@/components/GoogleAuth.vue";

export default{
  name : "LoginView",
  components:{
    GoogleAuth
  },
  data()
  {
    return {
      showPassword: false,
      loading:false,
      loginUser : {
        username: '',
        password: '',
        remember_me: false,
        login_error: false,
      }
    }
  },
  setup() {      
    return {
      v$: useVuelidate(),
    }
  },  
  validations() {
    return {
      loginUser: {
        username: {
          required: helpers.withMessage('Email/Mobile is required', required),
        },
        password: { 
          required: helpers.withMessage('password is required', required),
          minLength: minLength(6),
        }
      },
    }
  },
  methods:{
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async forgot() {
  this.$router.push('/forgot-password');
},
    async loginAction()
    {
      this.v$.loginUser.$touch();
      this.loading = true;
      if (!this.v$.loginUser.$invalid)
      {
        this.loginUser.login_error = false;
        console.log("validated");
        

        // calling api
        const API_URL = `https://api.qurangpt.com/api/v1/user/login_manual/`;
        const formData = axios.toFormData(this.loginUser)

        axios.post(API_URL, formData)
        .then((response) => {
          if(response.status == 203)
          {
            
            swal({
              title: "danger",
              text: response.data.message,
              icon: "danger",
            }); 
            
          }
          else if(response.status == 201)
          {
            // this.$toast.success(response.data.message, {
            //   icon: "check",
            // });
            swal({
              title: "Success",
              text: response.data.message,
              icon: "success",
            }); 
            this.$store.commit('SET_USER', response.data.data.user)
            this.$store.commit('SET_TOKEN', response.data.data.token)
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_USER_AVATAR', response.data.data.user.avatar)
            location.reload()

          }
          this.loading = false;
          // this.$router.push({ name: 'DashboardScreen' })
        })
        // calling api


        .catch((error) => {
          // Handle errors
          console.log(error);
          // this.$toast.error("Login failed, an unexpected error occured. Please try again later");
          swal({
            title: "danger",
            text: "Login failed, an unexpected error occured. Please try again later",
            icon: "danger",
          }); 
          this.loading = false;
        });
       
      }
      else
      {
        this.loading = false;
        console.log("not done");
        // this.$toast.error("Please fill the required fields", {
        //     icon: "dangerous",
        // });
        swal({
          position: 'top-end',
          icon: 'error',
          title: 'Please fill the required fields',
          showConfirmButton: false,
          timer: 1500
        });
        console.log(this.v$.loginUser.$errors)
        this.loginUser.login_error = true;
      } 
    }
  },
}
</script>