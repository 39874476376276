import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import Surah from '../views/Surah.vue'
import Chapter from '@/views/Chapter.vue'
import Duas from "@/views/Duas.vue"
import SurahDetailsView from '../views/SurahDetailsView.vue'
import ChapterDetailsView from '@/views/ChapterDetailsView.vue'
import Editions from '@/views/Editions.vue'
import QuranPageView from '@/views/QuranPageView.vue'
import DownloadAppPage from '@/views/DownloadAppPage.vue'
import DayTrackerView from '@/views/DayTrackerView.vue'

import NotFound from "@/views/NotFound.vue";

import RegisterView from '@/views/auth/RegisterView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'


// information pages
import AboutUsVue from '@/views/AboutUs.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsAndConditionsVue from '@/views/TermsAndConditions.vue'
// information pages

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditionsVue',
    component: TermsAndConditionsVue
  },
  {
    path: '/about',
    name: 'AboutUsVue',
    component: AboutUsVue
  },
  {
    path: '/download',
    name: 'DownloadAppPage',
    component: DownloadAppPage
  },
  {
    path: '/day-tracker',
    name: 'DayTrackerView',
    component: DayTrackerView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView
  },
  {
    path: '/surah',
    name: 'Surah',
    component: Surah
  },
  {
    path: '/chapter',
    name: 'Chapter',
    component: Chapter
  },  
  {
    path: '/duas',
    name: 'Duas',
    component: Duas
  },  
  {
    path: '/editions',
    name: 'Editions',
    component: Editions
  },
  {
    path: '/reading/:edition_id/:page',
    name: 'QuranPageView',
    component: QuranPageView,
    props: true 
  },    
  // {
  //   path: '/surah/details/:surah_id',
  //   name: 'SurahDetailsView',
  //   component: SurahDetailsView,
  //   props: true 
  // },
  {
    path: '/surah/:surah_title/:surah_id',
    name: 'SurahDetailsView',
    component: SurahDetailsView,
    props: true 
  },
  {
    path: '/chapter/details/:chapter_id',
    name: 'ChapterDetailsView',
    component: ChapterDetailsView,
    props: true 
  },

  
  {
    path: "/:pathMatch(.*)",
    component: NotFound,
  },
  { path: '/:pathMatch(.*)*', redirect: '/'  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
