<template>
  <div id="wrapper">
    <div class="container">
      <div class="row justify-content-center align-items-center py-5">
        <div class="col-sm-10 col-md-10 text-center">
          <SearchBar hide_logo="true" hide_search="true" />
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <nav aria-label="breadcrumb" class="text-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Landing' }">Rehal</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Editions</li>
      </ol>
    </nav>
  </div>
  <div class="wrapper_bg" style="min-height: 100vh;">
    <div class="container mt-3">
      <div class="row g-4 flex-column flex-md-row mb-5">
        <div class="col-lg-10">
          <div class="text-center text-md-start">
            <h1 class="headline_3 mb-0">All Editions</h1>
          </div>
        </div>
        <div class="col">
          <label>Language</label>
          <select class="form-control" v-model="selectedLanguage" @change="fetchEditionData()">
            <option value="">All</option>
            <option v-for="item in sortedLanguageList" :key="item.language_code" :value="item.language_code">{{
              item.language_name }}</option>
          </select>
        </div>
      </div>
      <div v-if="loading">
        <div class="row justify-content-md-center g-4">
          <div class="col-md-4">
            <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100" style="height: 30px">
              <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" style="width: 100%">
                Loading</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4" v-else>
        <div class="col-md-6 col-lg-3 " v-for="edition in edition_list" :key="edition">
          <router-link :to="{ name: 'DownloadAppPage' }"
            class="card cover_card text-center text-capitalize h-100 text-decoration-none">
            <div class="card-body py-5">
              <div class="card-number"><i class="bi bi-person-circle"></i></div>
              <h1>{{ edition.english_name }}</h1>
              <h2>{{ edition.name }}</h2>
              <p class="d-block">Language : {{ edition.language_name }}</p>
              Type : {{ edition.type }}

              <div class="btn-group d-block mt-3" role="group" aria-label="Default button group">
                <button type="button" class="btn btn-outline-warning" v-if="edition.format == 'text'"><i
                    class="bi bi-vector-pen"></i></button>
                <button type="button" class="btn btn-outline-warning" v-if="edition.format == 'audio'"><i
                    class="bi bi-headphones"></i></button>
              </div>
            </div>

            <!-- <router-link :to="{ name:'QuranPageView', params: { edition_id:edition.identifier,page:1 } }" class="button py-2 px-5 mt-3" @click="changeEdition(edition.identifier)">
                Read
              </router-link> -->
          </router-link>
        </div>
      </div>

    </div>
  </div>

  <div class="bg-white">
    <FooterVue></FooterVue>
  </div>

</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios';
import SearchBar from '@/components/SearchBar.vue';
import FooterVue from '@/components/Footer.vue';

import { useHead } from "@vueuse/head";

export default {
  name: 'RihaalView',
  components: {
    SearchBar,
    FooterVue
  },
  data() {
    return {
      selectedLanguage: '',
      language_list: [],
      edition_list: [],
      fetchedData: [],
      loading: true
    };
  },
  setup() {
    useHead({
      title: `Editions of Quran - Quran GPT`,
      meta: [
        {
          name: 'description',
          content: 'The Quran is written in different languages. Here of some of them',
        },
      ],
    })
  },
  computed: {
    sortedLanguageList() {
      return this.language_list.slice().sort((a, b) => a.language_name.localeCompare(b.language_name));
    },},
    mounted() {
      this.fetchEditionData();
      this.fetchLanguagesData();
      if (this.$store.getters.isAuthenticated == false) {
        this.$swal.fire({
          title: "<strong>Kindly log in to proceed.</strong>",
          icon: "info",
          html: `           
            For full access to all features, please log in or register.
            <br>
            <br>
            <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" class="button">Login</a>
          `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          showConfirmButton: false,
        });
      }

    },

    methods: {

      async fetchEditionData() {
        this.loading = true;
        try {
          let apiUrl = 'https://api.qurangpt.com/api/v1/user/quran/editions';
          if (this.selectedLanguage) {
            apiUrl += `/${this.selectedLanguage}`;
          }
          const response = await axios.get(apiUrl);
          console.log('helloworld', response);
          this.edition_list = response.data.data;
          this.loading = false;
        } catch (error) {
          Swal.fire({
            title: 'Sorry',
            text: 'Editions not found on this selection',
            icon: 'error',
            showConfirmButton: false,
          });
          this.loading = false;
        }
      },

      async fetchLanguagesData() {
        try {
          const response = await axios.get(`https://api.qurangpt.com/api/v1/user/quran/languages/`);
          this.language_list = response.data.data.sort();
          console.log(this.language_list);
          this.loading = false;
        } catch (error) {
          console.error('Error fetching data:', error);
          this.loading = false;
        }
      },

    }
  }
</script>
