<template>
  <main>
    <div class="card h-100 page_card">
      <div class="card-header p-0">
        <div class="container-xxl">
          <div class="row py-2">
            <div class="col-2 col-md align-self-center order-1">
              <button class="button py-2 px-2 px-md-4" @click="pageback()"><span><i class="bi bi-chevron-left"></i>
                  <span class="d-none d-md-inline-block">Back</span></span></button>
            </div>
            <div class="col-10 col-md  align-self-center text-end order-2 order-md-3 text-end">
              <button class="button py-2 px-3" type="button" @click="showOffcanvas = true">Change Editions</button>
            </div>
            <div class="col-12 col-md align-self-center order-0 py-2 py-md-0 order-3 order-md-2">
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <div class="card-number text-center mb-0 me-2">{{ fetchedData.number }}</div>
                </div>
                <div>
                  <h3 class="text-start mb-0">Chapter</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TextToSpeech ref="textReader"></TextToSpeech>
      </div>
      <div class="card-body overflow-y-scroll p-0" ref="containerBody">
        <div class="container-xxl">
          <div class="d-flex justify-content-between mt-2">
            <nav aria-label="breadcrumb" class="text-center">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Landing' }">Rehal</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Chapter' }">Chapters</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Chapter {{ fetchedData.number }}</li>
              </ol>
            </nav>
            <div class="form-check form-switch">
              <input class="form-check-input" v-model="reading_mode" type="checkbox" role="switch"
                id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Reading Mode</label>
            </div>
          </div>
        </div>
        <div class="container-xxl pb-5">
          <div v-if="loading">
            <div class="row justify-content-md-center">
              <div class="col-md-4">
                <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100"
                  aria-valuemin="0" aria-valuemax="100" style="height: 30px">
                  <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" style="width: 100%">
                    Loading</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              <div class="ayat_card p-0" v-if="reading_mode">
                <div class="card-header d-flex justify-content-between align-items-center">
                  <div class="">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                        v-model="reading_mode_feature.arabic_text">
                      <label class="form-check-label" for="inlineCheckbox1">Arabic</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
                        v-model="reading_mode_feature.arabic_text_trans">
                      <label class="form-check-label" for="inlineCheckbox2">Transliteration</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox3"
                        v-model="reading_mode_feature.meaning_text">
                      <label class="form-check-label" for="inlineCheckbox3">Meaning</label>
                    </div>
                  </div>

                </div>
                <div class="card-body">
                  <div
                    v-if="reading_mode_feature.arabic_text || reading_mode_feature.arabic_text_trans || reading_mode_feature.meaning_text">
                    <div class="mb-4 text-center" v-for="(ayah, index) in fetchedData.ayahs" :key="index">
                      <p class="font-family-arabic arabic-line text-end" v-if="reading_mode_feature.arabic_text">
                      {{ayah.quran_text }}</p>
                      <p class="m-0 p-0 text-secondary text-end mb-3" v-if="reading_mode_feature.arabic_text_trans">{{
                        ayah.transliteration }}</p>
                      <div v-if="reading_mode_feature.meaning_text">
                        <!-- <p class="text-link text-end font-family-urdu h4" v-if="fetchedData.edition.language === 'ur'">
                          {{ ayah.text }}
                        </p> -->
                        <!-- Add other language-specific conditions here -->
                        <p class="text-link text-start h4">
                          {{ ayah.text }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mb-3 text-center" v-for="item in fetchedData.ayahs" :key="item">
                      <p class="font-family-arabic arabic-line  text-end">{{ item.quran_text }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="loading">
                  <div class="row justify-content-md-center">
                    <div class="col-md-4">
                      <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100"
                        aria-valuemin="0" aria-valuemax="100" style="height: 30px">
                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                          style="width: 100%">Loading</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row g-3">
                    <div class="col-md-12 text-start" v-for="(ayah, index) in fetchedData.ayahs" :key="index">
                      <div class="ayat_card">
                        <div class="card-header">
                          <div class="d-flex justify-content-between">
                            <div>
                              <badge>Ayat: {{ ayah.number }}</badge>
                            </div>
                            <div>
                              Surah : <span>{{ ayah.surah.name }} ({{ ayah.surah.englishName }})</span>
                            </div>
                          </div>
                        </div>
                        <hr>
                        <div class="card-body">

                          <div class="row g-3">
                            <div class="col-12 col-md-12 text-center ">
                              <div class="border-bottom mb-3 pb-2">
                                <h1 class="font-family-arabic ">{{ ayah.quran_text }}</h1>
                                <i class="m-0 p-0 text-secondary">{{ ayah.transliteration}}</i>
                              </div>
                              <p :class="fontfam" class="text-link text-center h4">
                                {{ ayah.text }}
                              </p>
                              <!--<p class="text-link text-center :class=language_code font-family-arabic h4"
                                v-else-if="fetchedData.edition.language == 'ar'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-arabic h2"
                                v-else-if="fetchedData.edition.language == 'ps'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-arabic h2"
                                v-else-if="fetchedData.edition.language == 'ug'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-arabic h2"
                                v-else-if="fetchedData.edition.language == 'sd'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-arabic h2"
                                v-else-if="fetchedData.edition.language == 'ku'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-arabic h4"
                                v-else-if="fetchedData.edition.language == 'dv'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-arabic h2"
                                v-else-if="fetchedData.edition.language == 'fa'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-bangal h4"
                                v-else-if="fetchedData.edition.language == 'bn'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-hindi h4"
                                v-else-if="fetchedData.edition.language == 'hi'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center noto-sans-thai h2"
                                v-else-if="fetchedData.edition.language == 'th'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-japan h4"
                                v-else-if="fetchedData.edition.language == 'ja'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-tamil h4 "
                                v-else-if="fetchedData.edition.language == 'ta'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center font-family-malayalam h4"
                                v-else-if="fetchedData.edition.language == 'ml'">
                                {{ item.text }}
                              </p>
                              <p class="text-link text-center h4" v-else>
                                {{ item.text }}
                              </p> -->
                              <!-- {{ item.text }} -->
                            </div>
                          </div>

                          <!-- {{ fetchedData.edition.language }} -->
                        </div>
                        <div class="card-footer">
                          <div class="row">
                            <div class="col-6 col-md-6">
                              <div class="text-start">
                                <button class="play_button" @click="readText(ayah.text, fontfam)">
                                  <i class="bi bi-volume-up"></i> Meaning
                                </button>
                              </div>
                            </div>
                            <div class="col-6 col-md-6">
                              <div class="text-end">
                                <button class="play_button"
                                  @click="playVoice('https://cdn.islamic.network/quran/audio/64/ar.alafasy/' + ayah.number + '.mp3')">
                                  <i class="bi bi-volume-up"></i> Arabic
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="container-xxl">

          <div class="d-flex flex-column flex-md-row gap-3 align-items-center justify-content-between">

            <div class="d-flex gap-3 justify-content-center  w-100">
              <a class="button py-2 px-2 px-md-4" @click="prevPage()" v-if="this.chapter_id > 1"><span><i
                    class="bi bi-chevron-left"></i> <span>Prev</span></span></a>
              <a class="button py-2 px-2 px-md-4" @click="nextPage()"
                v-if="this.chapter_id < 30"><span><span>Next</span> <i class="bi bi-chevron-right"></i></span></a>
            </div>
          </div>
        </div>

      </div>
    </div>

  </main>

  <div class="offcanvas offcanvas-bottom shadow-xxl" :class="{ 'show': showOffcanvas }" tabindex="-1" id="editionModal"
    aria-labelledby="editionModalLabel">
    <div class="offcanvas-header bg-light">
      <h5 class="offcanvas-title" id="editionModalLabel">Change Edition</h5>
      <button type="button" @click="showOffcanvas = false" class="btn-close" data-bs-dismiss="offcanvas"
        aria-label="Close"></button>
    </div>
    <div class="offcanvas-body small">
      <div class="container-xxl">
        <form class="row g-3" @submit.prevent="fetchData()" v-if="$store.getters.isAuthenticated">
          <div class="col-md">
            <label>Language</label>
            <select class="form-control" v-model="filter_edition.language" @change="fetchEditionData()" required>
              <option value="all">All</option>
              <option v-for="item in language_list" :key="item.language_code" :value="item.language_code">{{ item.language_name }}</option>
            </select>
          </div>
          <div class="col-md">
            <label>Editions: <span class="badge bg-danger">{{ edition_list.length }}</span></label>
            <select :disabled="edition_list.length === 0" class="form-control text-capitalize" v-model="edition_name" @change="changeEdition()" required>
              <option v-for="item in edition_list" :key="item" :value="item.identifier">{{ item.english_name }}</option>
            </select>
          </div>
          <div class="col-md-12 text-center">
            <button type="submit" class="button button py-2 px-3" @click="showOffcanvas = false">Submit</button>
          </div>
        </form>
        <div v-else class="text-center">
          <a @click="menuToggle = false" data-bs-toggle="modal" data-bs-target="#authModal"
            class="button py-2 px-2 px-md-5"><i class="bi bi-person"></i> Login</a>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import axios from 'axios';
import Swal from 'sweetalert2'
// import AudioPlayer from '../components/AudioPlayer.vue';
import TextToSpeech from '@/components/TextToSpeech.vue';

import { useHead } from "@vueuse/head";
export default {
  name: 'RihaalView',
  setup() {
    useHead({
      title: `Chapter of Quran`,
    })
  },
  components: {
    TextToSpeech
  },
  props: {
    chapter_id: String,
  },
  data() {
    return {
      showOffcanvas: false,
      langCodes: 'language_code_to_name',
      language_list: [],
      fetchedData: [],
      fontfam:'',
      fetchedDataread: [],
      edition_list: [],
      chapter_number: this.chapter_id,
      filter_edition: {
        language: "all",
      },
      edition_name: this.$store.getters.getEdition,
      loading: true,
      play_voice: "",
      reading_mode: false,
      reading_mode_feature: {
        arabic_text: true,
        arabic_text_trans: false,
        meaning_text: false
      }
    };
  },
// mounted(){
  //   this.fetchEditionData()
  // },
  created() {
    this.fetchData();
      this.fetchLanguagesData();
    this.fetchEditionData();
    if (this.$store.getters.isAuthenticated == false) {
      this.$swal.fire({
        title: "<strong>Kindly log in to proceed.</strong>",
        icon: "info",
        html: `           
            For full access to all features, please log in or register.
            <br>
            <br>
            <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" class="button">Login</a>
          `,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showConfirmButton: false,
      });
    }
  },

  methods: {
    scrollToTop() {
      // window.scrollTo(0,0);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // });

      const containerBody = this.$refs.containerBody;
      if (containerBody) {
        containerBody.scrollTop = 0;
      }
    },
    playVoice(url) {
      this.play_voice = url;
      // this.$refs.controlPlayer.play(); // Call the child component's function
      this.$refs.textReader.readText("text", "language", this.play_voice);
    },
    pageback() {
      this.chapter_number = parseInt(this.chapter_id) - 1
      this.$router.go(-1);
      this.fetchData();
    },
    async fetchLanguagesData() {
      try {
        const response = await axios.get(`https://api.qurangpt.com/api/v1/user/quran/languages/`);
        this.language_list = response.data.data;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.loading = false;
      }
    },
    async fetchEditionData() {
      try {
        const response = await axios.get(`https://api.qurangpt.com/api/v1/user/quran/editions/${this.filter_edition.language}/`);
        this.edition_list = response.data.data;
      } catch (error) {
        Swal.fire({
          title: 'Sorry',
          text: 'Editions not found on this selection',
          icon: 'error',
          showConfirmButton: false,
        })
      }
    },
    async fetchData() {
      this.loading = true;
      this.showOffcanvas = false;
      try {
        const response = await axios.get(`https://api.qurangpt.com/api/v1/user/quran/ayahs-by-juz/${this.chapter_number}/${this.edition_name}/`);
        this.fetchedData = response.data.data;
        this.fontfam=response.data.edition.language;
        this.fetchedDataread = response.data.data.ayahs;
        this.addAsRecentViewChapter(response.data.data);
        if ((response.data.data.ayahs).length > 0) {
          this.loading = false;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    readText(text, language) {
      this.$refs.textReader.readText(text, language); // Call the child component's function
    },
    changeEdition() {
      this.$store.commit('SET_EDITIONAL', this.edition_name);
    },
    prevPage() {
      this.chapter_number = parseInt(this.chapter_id) - 1
      this.$router.push({ name: 'ChapterDetailsView', params: { chapter_id: this.chapter_number } })
      this.fetchData();
      this.scrollToTop();
    },
    nextPage() {

      this.chapter_number = parseInt(this.chapter_id) + 1
      this.$router.push({ name: 'ChapterDetailsView', params: { chapter_id: this.chapter_number } })
      this.fetchData();
      this.scrollToTop();
    },

    addAsRecentViewChapter(fetchedData) {
      let getRChapters = this.$store.getters.getRChapters
      if (getRChapters.filter(item => item.number == fetchedData.number).length == 0) {
        // alert("its unique")
        this.$store.commit('SET_R_CHAPTERS', fetchedData);
      }
      else {
        // alert("its not unique")
      }
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

#wrapper {
  background: #F4F4F4 url("../assets/bg-quran.png") bottom center no-repeat;
  background-size: contain;
}

.page-border-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  background: url("../assets/border2.png") center;
  background-size: contain;
  height: 30px;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  #wrapper {
    background: #F4F4F4 url("../assets/bg-quran.png") bottom center no-repeat;
    background-size: cover;
  }

  .page-border-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    background: url("../assets/border2.png") center repeat-x;
    background-size: contain;
    height: 30px !important;
    z-index: 10;
  }
}




main {
  border-radius: 6px;
  border: 1px solid #E3B232;
  background: #F8F8F8;
  position: relative;
  overflow-y: hidden !important;
  height: 100dvh;
}

body {
  overflow: hidden !important;
}

/* main .page-border-top
{
  background: url("../assets/border2.png") center repeat-x;
  background-size: contain;
  height: 40px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
main .page-border-bottom
{
  background: url("../assets/border2.png") center repeat-x;
  background-size: contain;
  height: 40px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
main .page-border-left
{
  background: url("../assets/border1.png") center repeat-y;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 40px;
  top: 0;
  left: 0;
  z-index: 10;
}
main .page-border-right
{
  background: url("../assets/border1.png") center repeat-y;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 40px;
  bottom: 0;
  right: 0;
  z-index: 10;
} */
@media only screen and (max-width: 600px) {
  main .card {
    padding: 30px 0;
  }

  main .page-border-top {
    background-size: contain;
    height: 40px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  main .page-border-bottom {
    background: url("../assets/border2.png") center repeat-x;
    background-size: contain;
    height: 40px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  main .page-border-left {
    display: none;
  }

  main .page-border-right {
    display: none;
  }
}
</style>