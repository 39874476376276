<template>
  <div>
    <center>
      <div class="btn-group btn-group-lg" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" class="btn-check" name="btnradio" id="login" value="login" v-on:click="selectedTab='login'" checked>
        <label class="btn btn-outline-warning" for="login">Login</label>

        <input type="radio" class="btn-check" name="btnradio" id="register" value="register" v-on:click="selectedTab='register'">
        <label class="btn btn-outline-warning" for="register">Register</label>
      </div>
    </center>
    <br>
    <Login v-if="selectedTab == 'login'"></Login>
    <Register v-if="selectedTab == 'register'"></Register>
  </div>
</template>
<script>
  import Login from "./LoginView.vue" 
  import Register from "./RegisterView.vue" 

  export default{
    name: "AuthView",
    components:{
      Login,
      Register
    },
    data()
    {
      return {
        selectedTab:"login"
      }
    }
  }
</script>