<template>
    <div class="music-player">
        <audio ref="audio" :src="musicUrl" ></audio>
        <!-- <button @click="togglePlay">{{ isPlaying ? 'Pause' : 'Play' }}</button> -->
    </div>
</template>

<script>
    export default {
        props: {
            musicUrl: String,
        },
        data() {
            return {
                isPlaying: false,
            };
        },
        
        methods: {
            togglePlay() {
                const audio = this.$refs.audio;
                if (this.isPlaying) {
                    audio.pause();
                } else {
                    audio.play();
                }
                this.isPlaying = !this.isPlaying;
            },
            play() {
                const audio = this.$refs.audio;
                setTimeout( function() {                     
                    audio.play();
                    this.isPlaying = true;
                }, 1000);
            },
            pause() {
                const audio = this.$refs.audio;
                audio.pause();
                this.isPlaying = false;
            },
            stop() {
                const audio = this.$refs.audio;
                audio.pause();
                audio.currentTime = 0;
            },
        },
    };
    </script>

    <style scoped>
    /* .music-player {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    } */


    audio {
        /* width: auto; */
        display: block;
        border-radius: 0px !important;
    }

    button {
        background-color: #0074d9;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        margin-top: 10px;
    }

    button:hover {
        background-color: #0056b3;
    }


    /* audio tag */
    audio::-webkit-media-controls-panel
    {
        background: #f8f8f8;
        border-radius: 0;
        width: 100%;
    }


    /* audio::-webkit-media-controls-panel
    audio::-webkit-media-controls-mute-button
    audio::-webkit-media-controls-play-button
    audio::-webkit-media-controls-timeline-container
    audio::-webkit-media-controls-current-time-display
    audio::-webkit-media-controls-time-remaining-display
    audio::-webkit-media-controls-timeline
    audio::-webkit-media-controls-volume-slider-container
    audio::-webkit-media-controls-volume-slider
    audio::-webkit-media-controls-seek-back-button
    audio::-webkit-media-controls-seek-forward-button
    audio::-webkit-media-controls-fullscreen-button
    audio::-webkit-media-controls-rewind-button
    audio::-webkit-media-controls-return-to-realtime-button
    audio::-webkit-media-controls-toggle-closed-captions-button */
</style>
  