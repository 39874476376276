<template>
    
    <div class="container">
        <div class="row pt-5 pb-3 g-4">
            <div class="col-12 text-center col-md-6 text-md-start text-body-secondary">
                <p class="text-body-secondary p-0 m-0">© 2023 - 2024 Copyright by Quran GPT</p>
                <p class="text-body-secondary p-0 m-0">Developed and maintained by <a style="color: #BA9527;" href="http://9xtechnology.com">9x Technology LLC</a></p>
            </div>
            <div class="col-12 col-md-6" v-if="download_icons != 'false'">
                <div class="d-flex gap-2 justify-content-center justify-content-md-end footer_icons">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.qurangpt.application"><img :src="google_play_store" class="apps_store_button"></a>
                    <a target="_blank" href="https://apps.apple.com/ae/app/quran-gpt/id6475193824"><img :src="apple_app_store" class="apps_store_button"></a>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.apps_store_button
{
    height: 50px;
}
@media only screen and (max-width: 600px) {
    .apps_store_button
    {
        height: 35px;
    }
}
</style>
<script>
    import google_play_image from "../assets/Google Play.png"
    import apple_app_image from "../assets/App Store.png"
    import iphone from "../assets/iPhone.png"
    import logo from "../assets/logo-quran-gpt.png"
    import qr_code from "../assets/qr.jpg"

    export default
    {
        name: "FooterVue",
        data()
        {
            return {
                google_play_store : google_play_image,
                apple_app_store : apple_app_image,
                iphone : iphone,
                logo : logo,
                qr_code : qr_code
            }
        },
        props:['download_icons']
    }
</script>