<script>
const language_code_to_name = {
  am:"Amharic",
  ar:"Arabic",
  az:"Azerbaijani",
  ber:"Berber",
  bn:"Bangla",
  cs:"Czech",
  de:"German",
  dv:"Maldivian",
  en:"English",
  es:"Spanish",
  fa:"Persian",
  fr:"French",
  ha:"Hausa",
  hi:"Hindi",
  id:"Indonesian",
  it:"Italian",
  ja:"Japanese",
  ko:"Korean",
  ku:"Kurdish",
  ml:"Malayalam",
  nl:"Dutch",
  no:"Norwegian",
  pl:"Polish",
  ps:"Pashto, Pushto",
  pt:"Portuguese",
  ro:"Romanian",
  ru:"Russian",
  sd:"Sindhi",
  so:"Somali",
  sq:"Albanian",
  sv:"Swedish",
  sw:"Swahili",
  ta:"Tamil",
  tg:"Tajik",
  th:"Thai",
  tr:"Turkish",
  tt:"Tatar",
  ug:"Uighur",
  ur:"Urdu",
  uz:"Uzbek"
}
import Swal from 'sweetalert2'
import axios from 'axios';
import AudioPlayer from '../components/AudioPlayer.vue';
import TextToSpeech from '@/components/TextToSpeech.vue';

import { useHead } from "@vueuse/head";


export default {
    name: 'QuranReadingView',
    props: {
      edition_id: String,
      page: String,
    },
    components:{
      AudioPlayer, TextToSpeech
    },
    data() {
      return {
        langCodes: language_code_to_name,
        fetchedData: [],
        fetchedDataArabic: [],
        edition_list: [],
        reading: {
          page: this.page,
          edition_name: this.edition_id,   
          edition: {
            name:"",
            englishName:"",
            format:""
          },          
        },
        loading: true,
        play_voice: ""
      };
    },
    setup() {      
      useHead({
          title: `Edition of Quran - Quran GPT`
      })
    }, 
    created() {
      this.fetchData();
    },
    methods: {
      playVoice(url)
      {
        this.play_voice = url;
        // this.$refs.controlPlayer.play(); // Call the child component's function
        this.$refs.textReader.readText("text","language", this.play_voice);
      },
      async fetchData() {
        this.loading = true;
        try {
          
          const response = await axios.get(`https://api.alquran.cloud/v1/page/${this.reading.page}/${this.reading.edition_name}`);
          const response_arabic = await axios.get(`https://api.alquran.cloud/v1/page/${this.reading.page}/quran-uthmani`);
          this.fetchedData = response.data.data;
          this.fetchedDataArabic = response_arabic.data.data


          // edition data
          this.reading.edition.name = response.data.data.edition.name
          this.reading.edition.englishName = response.data.data.edition.englishName
          this.reading.edition.format = response.data.data.edition.format
          // edition data
    

          this.loading = false;
        
        } catch (error) {
          console.error('Error fetching data:', error);
          Swal.fire({
            title: 'Sorry',
            text: 'Data not found',
            icon: 'error',
            showConfirmButton: false,
          })
        }
      },
      readText(text,language)
      {
        this.$refs.textReader.readText(text,language); // Call the child component's function
      },
      pageback()
      {
        if(this.reading.page > 1)
        {
          this.reading.page = parseInt(this.reading.page)-1
          this.fetchData();
        }
        this.$router.go(-1);
      },
      prevPage()
      {
        this.reading.page = parseInt(this.reading.page)-1
        this.$router.push({ name:'QuranPageView', params: { edition_id:this.reading.edition_name.identifier, page:this.reading.page } })
        this.fetchData();
      },
      nextPage()
      {
        this.reading.page = parseInt(this.reading.page)+1
        this.$router.push({ name:'QuranPageView', params: { edition_id:this.reading.edition_name.identifier, page:this.reading.page } })
        this.fetchData();
      }
    }
}
</script>


<template>
  <main class="vh-100">
    <!-- <div class="page-border-top"></div>
    <div class="page-border-bottom"></div>
    <div class="page-border-left"></div>
    <div class="page-border-right"></div> -->
    <div class="card h-100 page_card ">
      <div class="card-header">

        <!-- <nav>
          <div class="d-flex">
            <div>
              <button class="button" @click="$router.go(-1)">
                <span><i class="bi bi-chevron-left"></i> <span class="d-none d-md-inline-block">Back</span></span>
              </button>
            </div>
            <div class="flex-grow-1 text-center">
              Page <div class="card-number text-center mb-0 me-2">{{ fetchedData.number }}</div>
            </div>
          </div>
        </nav> -->

        <div class="container-xxl">
          <div class="row">
            <div class="col-6 col-md align-self-center order-1">
              <button class="button py-2 px-2 px-md-4" @click="pageback()"><span><i class="bi bi-chevron-left"></i> <span class="d-none d-md-inline-block">Back</span></span></button>
            </div>
            <div class="col-12 col-md align-self-center order-0 py-2 py-md-0 order-3 order-md-2">
              <div class="d-flex justify-content-center">
                <div>
                  <div class="d-flex align-items-center">
                    <div>
                      <span class="card-number-2 text-center "><i class="bi bi-person-circle"></i></span>
                    </div>
                    <div class="p-2">
                      <h3 class="mb-0">{{ reading.edition.name }}</h3>
                      {{ reading.edition.englishName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-md align-self-center text-end order-2 order-md-3">
              <a class="button py-2 px-2 px-md-4" @click="prevPage()" v-if="reading.page > 1"><span><i class="bi bi-chevron-left"></i> <span class="d-none d-md-inline-block">Prev</span></span></a>
              <a class="button py-2 px-2 px-md-4" @click="nextPage()"><span><span class="d-none d-md-inline-block">Next</span> <i class="bi bi-chevron-right"></i></span></a>
            </div>
          </div>
        </div>

        
       
      </div>
      <div class="card-body overflow-y-scroll p-0 p-md-4">
        
        <div class="container-xxl">
          <div v-if="loading">
            <div class="row justify-content-md-center my-5 py-5">
              <div class="col-md-4">
                <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height: 30px">
                  <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" style="width: 100%">Loading</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="d-flex justify-content-center py-2">
              <nav aria-label="breadcrumb" class="text-center">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name:'Landing' }">Rehal</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="{ name:'Editions' }">Editions</router-link>
                  </li>
                  <li class="breadcrumb-item text-capitalize active">{{ langCodes[fetchedData.edition.language] }}</li>
                </ol>
              </nav>
            </div>
            <div class="row g-3">
              <div class="col-md-12 text-start" v-for="(item, index) in fetchedData.ayahs" :key="item">
                <div class="ayat_card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <div>
                        <badge>Ayat: {{ item.number }}</badge>
                      </div>
                      <div>
                        Surah : <span>{{ item.surah.name }} ({{ item.surah.englishName }})</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
             
                    <div class="row g-3">
                      <div class="col-12 col-md-6 order-2 order-md-1">
                        <h5 class="text-secondary text-center text-md-start">                          
                          {{ item.text }}
                        </h5>
                      </div>
                      <div class="col-12 col-md-6 order-1 order-md-2 text-center text-md-end">
                        <h1 class="font-family-arabic">{{ fetchedDataArabic.ayahs[index].text }}</h1>
                      </div>
                    </div>
                    
    
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      
                      <div class="col-6 col-md-6">
                        <div class="text-start">
                          <label class="badge bg-info mb-2">Meaning</label><br>
                          <!-- <button  class="btn btn-warning text-white" @click="playVoice('https://cdn.islamic.network/quran/audio/192/en.walk/'+item.number+'.mp3')">
                            <i class="bi bi-megaphone-fill"></i>
                          </button> -->
                          <button  class="btn btn-warning text-white" @click="readText(item.text,fetchedData.edition.language)"><i class="bi bi-megaphone-fill"></i></button>

                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="text-end">
                          <label class="badge bg-info mb-2">Arabic</label><br>                        
                          <button class="btn btn-warning text-white" @click="playVoice('https://cdn.islamic.network/quran/audio/64/ar.abdurrahmaansudais/'+item.number+'.mp3')">
                            <i class="bi bi-megaphone-fill"></i>
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div class="card-footer text-center">
        <div class="container-xxl">
          <!-- <div class="d-flex gap-3 align-items-center justify-content-between ">
              <div class="d-md-block w-100 text-start">
                <AudioPlayer :musicUrl=play_voice ref="controlPlayer"></AudioPlayer>
                <TextToSpeech ref="textReader"></TextToSpeech>
              </div>
              <div class="d-md-block w-100 text-end">
                Page : {{ fetchedData.number }}
              </div>
          </div> -->

          <div class="d-flex flex-column flex-md-row gap-3 align-items-center justify-content-between">
            <div class="d-md-block w-100 text-start">
              <AudioPlayer :musicUrl=play_voice ref="controlPlayer"></AudioPlayer>
              <TextToSpeech ref="textReader"></TextToSpeech>
            </div>
            <div class="d-flex gap-3 justify-content-center justify-content-md-end w-100 text-center text-md-end">
              Page : {{ fetchedData.number }}
            </div>
          </div>

        </div>
        
      </div>
    </div>
    
    
  </main>
 
</template>

<style scoped>
.vh-100
{
  height: 100vh !important;
}
main{
  border-radius: 6px;
  border: 1px solid #E3B232;
  background: #F8F8F8;
  position: relative;
  overflow-y: hidden !important;
}
body
{
  overflow: hidden !important;
}
/* main .page-border-top
{
  background: url("../assets/border2.png") center repeat-x;
  background-size: contain;
  height: 40px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
main .page-border-bottom
{
  background: url("../assets/border2.png") center repeat-x;
  background-size: contain;
  height: 40px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
main .page-border-left
{
  background: url("../assets/border1.png") center repeat-y;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 40px;
  top: 0;
  left: 0;
  z-index: 10;
}
main .page-border-right
{
  background: url("../assets/border1.png") center repeat-y;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 40px;
  bottom: 0;
  right: 0;
  z-index: 10;
} */
@media only screen and (max-width: 600px) {
  main .card{
    padding: 30px 0;
  }
  main .page-border-top
  {
    background-size: contain;
    height: 40px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  main .page-border-bottom
  {
    background: url("../assets/border2.png") center repeat-x;
    background-size: contain;
    height: 40px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  main .page-border-left
  {
    display: none;
  }
  main .page-border-right
  {
    display: none;
  }
}
</style>

