<template>
  <form autocomplete="off" autosave="off" @submit.prevent="registerAction()">

    <div class="mb-3">
      <label for="name" class="form-label">First Name</label>
      <input type="text" class="form-control form-control-lg " id="name" v-model="registerUser.first_name">
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.first_name.$error">
  {{ v$.registerUser.first_name.$errors[0].$message }}
</span>
    </div>
    <div class="mb-3">
      <label for="last_name" class="form-label">Last Name</label>
      <input type="text" class="form-control form-control-lg " id="last_name" v-model="registerUser.last_name">
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.last_name.$error">
        {{ v$.registerUser.last_name.$errors[0].$message }} </span>
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">Email </label>
      <input type="email" class="form-control form-control-lg " id="email" v-model="registerUser.email">
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.email.$error"> {{
        v$.registerUser.email.$errors[0].$message }} </span>
    </div>
    <div class="mb-3 d-flex align-items-start flex-column">
      <div>

        <label for="phone" class="form-label">Phone </label>
      </div>
      <div class="d-flex gap-3">
      <select style="width: 80px;font-size: 12px" class="py-3 form-select form-control form-focus text-secondary"
        id="countryCode" v-model="registerUser.country_phone_code" @change="updateCountryCode">
        <option style="font-size: 14px;" v-for="(country, index) in Contrycode" :key="index" :value="country.dial_code">
          {{ country.code }} ({{
            country.dial_code }})
        </option>
      </select>
      
      <input type="tel" class="w-100 form-control form-control-lg " id="email" v-model="registerUser.mobile">
     
      
      </div>
      <div><span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.mobile.$error"> {{
        v$.registerUser.mobile.$errors[0].$message }} </span></div>
    </div>
    <div class="mb-3">
      <label for="password" class="form-label">Password</label>
      <div class="position-relative mt-3">
        <input :type="showPassword ? 'text' : 'password'" class="form-control form-control-lg " id="password" v-model="registerUser.password">
              <button @click="togglePassword" type="button"
                class="btn btn-link position-absolute end-0 top-50 translate-middle-y">
                <i class="text-secondary" :class="showPasswordtwo ? 'bi bi-eye-slash' : 'bi bi-eye-fill'"></i>
              </button>
            </div>
      <span class="text-danger w-100 text-end" v-if="registerUser.register_error && v$.registerUser.password.$error"> {{
        v$.registerUser.password.$errors[0].$message }} </span>
    </div>
    <div class="mb-3">
      <label for="promo_code" class="form-label">Promo Code</label>
      <input type="text" class="form-control form-control-lg " id="promo_code" v-model="registerUser.promo_code">
    </div>
    
    <div class="mb-3">
      <input type="checkbox" class="form-check-input" id="lucky_draw" v-model="registerUser.lucky_draw" :true-value="'1'" :false-value="'0'">
      <label for="lucky_draw" class="form-label ms-2">Participate in lucky draw for umrah</label>
    </div>
    <div class="d-grid gap-3 text-center">
      <button class="button py-2 px-5 mt-3">Create an account</button>
      <!-- <button class="theme-button-outline" type="button"><img src="../../assets/google.svg" height="20"> Sign in with Google</button> -->
      <GoogleAuth></GoogleAuth>
      <small>By creating an account you agree to Terms and Conditions</small>
    </div>
  </form>
</template>
<style scoped>
.navbar {
  display: none !important;
}
</style>
<script>
import swal from 'sweetalert';
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import axios from 'axios';
import Contrycode from '@/assets/country_codes.json';
import GoogleAuth from "@/components/GoogleAuth.vue";

export default {
  name: "RegisterView",
  components: {
    GoogleAuth,
  },
  data() {
    return {
      showPassword: false,
      Contrycode: Contrycode,
      api_base_url: process.env.VUE_APP_ROOT_API,
      registerUser: {
        first_name: '',
        last_name: '',
        email: '',
        mobile_country_code: '+91',
        mobile: '',
        password: '',
        promo_code: '',
        lucky_draw: '0', // Set to string '1' initially
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  mounted() {
    console.log(this.Contrycode);
  },
  validations() {
    return {
      registerUser: {
        email: {
          required: helpers.withMessage('Email is required', required),
        },
        first_name: {
          required: helpers.withMessage('First name is required', required),
        },
        last_name: {
          required: helpers.withMessage('Last name is required', required),
        },
        mobile: {
          required: helpers.withMessage('Mobile number is required', required),
        },
        password: {
          required: helpers.withMessage('Password is required', required),
          minLength: helpers.withMessage('Password must be at least 8 characters long', minLength(8)),
        },
      }
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    updateCountryCode(event) {
            this.registerUser.mobile_country_code = event.target.value;
        },
    async registerAction() {
      this.v$.registerUser.$touch();
      if (!this.v$.registerUser.$invalid) {
        this.registerUser.register_error = false;
        console.log("validated");
        this.registerUser.lucky_draw = String(this.registerUser.lucky_draw);

        // calling api
        const API_URL = `https://api.qurangpt.com/api/v1/user/register_manual/`;
        const formData = axios.toFormData(this.registerUser)

        axios.post(API_URL, formData)
          .then((response) => {
            if (response.status == 203) {
              // this.$toast.error(response.data.message, {
              //   icon: "dangerous",
              // }); 
              swal({
                title: "error",
                text: response.data.message,
                icon: "error",
              });
            }
            else if (response.status == 201) {
              // this.$toast.success(response.data.message, {
              //   icon: "check",
              // }); 
              swal({
                title: "success",
                text: response.data.message,
                icon: "success",
              });
              this.$store.commit('SET_USER', response.data.data.user)
              this.$store.commit('SET_TOKEN', response.data.data.token)
              this.$store.commit('SET_AUTHENTICATED', true)
              this.$store.commit('SET_USER_AVATAR', response.data.data.user.avatar)
              location.reload()
            }
            this.loading = false;
          })
        // calling api
      } else {
        console.log("not done");
        // this.$toast.error("Please fill the required fields", {
        //     icon: "dangerous",
        // });  
        swal({
          title: "error",
          text: "Please fill the required fields",
          icon: "error",
        });
        this.registerUser.register_error = true;
      }
    }
  }
}
</script>