<template>
    <section>
      <div class="container">
        <section v-if="recent_chapters.length > 0" class="mt-5">
          <div class="mb-5 text-center text-md-start">
            <h1 class="headline_3">Recent Chapters</h1>
            <a class="text-center text-link" @click="clearAllChapters">Clear all</a>
          </div>
          <Carousel v-bind="settings" :breakpoints="breakpoints">
            <slide v-for="slide in recent_chapters" :key="slide" class="p-2">
              <router-link :to="'/chapter/details/' + slide.number" class="d-block w-100 text-decoration-none">
                <div class="card cover_card text-center p-3 p-md-4">
                  <div class="card-body py-3 py-md-5">
                    <div class="card-number">{{ slide.number }}</div>

                    <h1>Chapter (Juz)</h1>
                  </div>
                </div>
              </router-link>
            </slide>
            
          </Carousel>
        </section>
      </div>
    </section>
  </template>
  
  <script>
  import swal from 'sweetalert';

  
  // for slider
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide } from 'vue3-carousel'
  // for slider
  
  export default {
      name: 'RecentChapter',
      data() {
        return {
          recent_chapters:this.$store.getters.getRChapters,
  
          // carousel settings
          settings: {
            itemsToShow: 2,
            snapAlign: 'center',
            gap: 10
          },
          // breakpoints are mobile first
          // any settings not specified will fallback to the carousel settings
          breakpoints: {
            // 700px and up
            700: {
              itemsToShow: 2,
              snapAlign: 'center',
            },
            // 1024 and up
            1024: {
              itemsToShow: 4,
              snapAlign: 'start',
            },
          },
        };
      },
      components: {
        Carousel,
        Slide,
      },
      mounted() {
      },
      methods: {
        clearAllChapters()
        {
          this.$store.commit('CLEAR_R_CHAPTERS');
          
  
          swal({
            title: "Success",
            text: "All recently chapter's history is cleard successfully",
            icon: "success",
          }).then(function() {
            location.reload();
          });
        }
      }
  }
  </script>
  
  <style scoped>
   @media only screen and (max-width: 600px) {
    .vh-100
    {
      height: auto !important;
      padding: 50px 0 !important;
    }
   }
  
  </style>
  