<template>
    <!-- <Header /> -->
    <section class="background-radial-gradient overflow-hidden">
      <div class="container justify-content-center align-items-center text-center text-lg-start d-flex vh-100">
        <div class="row gx-lg-5 align-items-center">
            <div class="d-flex justify-content-center py-3 ">
              <img :src=logo height="50" class="d-block">
            </div>
          <div class="col-lg-12 mb-5 mb-lg-0 " >
            <div class="bg-glass px-md-5">
              <div style="backdrop-filter: blur(10px); box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;" class="card-body py-5 px-4 border rounded-5">
                <div class="loginAndReg d-flex justify-content-center px-4 gap-5">
                  <h4 style="border-bottom: 1px solid var(--template-color-7);" class="headline_1 pb-2 fs-3">Forgot password</h4>
                </div>
                <div class="login-form py-4 px-3 d-flex flex-column text-start">
                  <div class="col-md-12 pb-4">
                    <label class="text-secondary pb-1">Email or Mobile number</label>
                    <!-- <div class="alert alert-warning p-2" style="font-size: clamp(9px, 4vw, 12px); width: max-content" role="alert"> Ensure country code for Mobile number eg:- +9198XXXXXXXX</div> -->
                    <input style="border: 1px solid #888;" type="text" v-bind="inputAttributes" required placeholder="enter your email or mobile " class="text-secondary form-control form-focus rounded-2 bg-transparent p-2" v-model="username"/>
                  </div>
                  <div v-if="passwordshow" style="transition: 1s;" class="col-md-12">
                    <label class="text-secondary pb-1" for="subject ">Create your Password</label>
                    <div class="position-relative mt-3">
                      <input :type="showPassword ? 'text' : 'password'" id="subject" class="text-secondary form-control rounded-0" v-model="password" autocomplete="current-password"
                      required />
              <button @click="togglePassword" type="button"
                class="btn btn-link position-absolute end-0 top-50 translate-middle-y">
                <i class="text-secondary" :class="showPasswordtwo ? 'bi bi-eye-slash' : 'bi bi-eye-fill'"></i>
              </button>
            </div>

                    <div v-if="passwordError" style="width:330px;" class="text-danger">
                      {{ passwordError }}
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-12 pt-3 gurugranth_Button">
                      <button v-if="checking" @click="forgotpassword"  class="w-100 button">Continue</button>
                      <button v-else @click="updatePassword" class="w-100 button">Create Password</button>
                    </div>
                  </div>
                </div>
                <div class="text-center text-secondary pb-5">
                  <p> Return to Login ?
                    <a style="font-size: 16px !important" href="#" @click="closeOffcanvas" data-bs-toggle="modal" data-bs-target="#authModal"
                      class="fw-bold text-decoration-none headline_1">signup</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
    
  </template>
  
  <script>
  // import Header from "@/components/AppHeader.vue";
  import Footer from "@/components/Footer.vue";
  import axios from "axios";
  import { useHead } from "@vueuse/head";
import swal from 'sweetalert';
import Logo from '@/assets/logo-quran-gpt.png'
  
  export default {
    name: "ForgotPasswordView",
    setup() {
      // console.log("useHead",useHead());
      useHead({
        title: "Forget Password - Gurugranth Sahib GPT",
        meta: [
          {
            name: "description",
            content: "Login to access your account on Gurugranth Sahib GPT.",
          },
        ],
      });
    },
    data() {
      return {
        showPassword: false,
        checking:true,
        logo:Logo,
        inputAttributes: {
          readonly: false,
          placeholder: "Enter your email or phone number",
          class: "form-control"
        },
        passwordshow: false,
        emailshow: true,
        username: "",
        password: "",
        passwordError: "",
        deviceInfo: {
          device: "",
          ip: "",
          location: {},
        },
      };
    },
    components: {
      // Header,
      Footer,
    },
    mounted() {
      this.$store.state.authentiation && this.$router.push("/");
      this.getDeviceAndIpInfo();
    },
    activated() {
      this.$store.state.authentiation && this.$router.push("/");
      this.device = this.getDeviceInfo();
      console.log("Device:", this.device);
    },
    methods: {
      togglePassword() {
      this.showPassword = !this.showPassword;
    },
      validatePassword() {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!regex.test(this.password)) {
        this.passwordError = "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.";
        return false;
      }
      this.passwordError = "";
      return true;
    },
      getDeviceInfo() {
        const userAgent = navigator.userAgent;
        let device = "Unknown Device";
  
        const deviceInfo = userAgent.match(/\(([^)]+)\)/);
        if (deviceInfo && deviceInfo[1]) {
          const parts = deviceInfo[1].split(";");
          if (parts.length > 0) {
            device = parts[0].trim();
          }
        }
  
        if (device === "Unknown Device") {
          if (/windows/i.test(userAgent)) {
            device = "Windows Device";
          } else if (/macintosh/i.test(userAgent)) {
            device = "Mac Device";
          } else if (/linux/i.test(userAgent)) {
            device = "Linux Device";
          } else if (/android/i.test(userAgent)) {
            device = "Android Device";
          } else if (/iphone|ipad|ipod/i.test(userAgent)) {
            device = "iOS Device";
          }
        }
  
        return device;
      },
      async getIpAddress() {
        try {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          return data.ip;
        } catch (error) {
          console.error("Error fetching IP:", error);
          return "Unknown";
        }
      },
      async getLocation(ip) {
        try {
          const response = await fetch(`https://ipapi.co/${ip}/json/`);
          const data = await response.json();
          return {
            country: data.country_name,
            region: data.region,
            city: data.city,
            latitude: data.latitude,
            longitude: data.longitude,
          };
        } catch (error) {
          console.error("Error fetching location:", error);
          return {
            country: "Unknown",
            region: "Unknown",
            city: "Unknown",
            latitude: "Unknown",
            longitude: "Unknown",
          };
        }
      },
      async getDeviceAndIpInfo() {
        this.deviceInfo.device = this.getDeviceInfo();
        this.deviceInfo.ip = await this.getIpAddress();
        this.deviceInfo.location = await this.getLocation(this.deviceInfo.ip);
        console.log("Device Info:", this.deviceInfo);
      },
      async forgotpassword() {
    try {
      const response = await axios.post(
        `https://api.qurangpt.com/api/v1/user/check-contact/`,
        { username: this.username }
      );

      if (response.status == 200) {
        this.checking = false;
        this.passwordshow = true;
        this.inputAttributes.readonly = true;
        swal({
      title: "Success",
      text: "Account verification success",
      icon: "success",
    }); 
   
      } else {
        swal({
      title: "Success",
      text: response.data.message.password,
      icon: "success",
    }); 
       
      }
      console.log("Forgot password successful:", response);
    } catch (error) {
      if (error.response && error.response.status == 400) {
        swal({
      title: "error",
      text: "Invalid contact details. Please try again.",
      icon: "error",
    });
      
      } else {
        swal({
      title: "error",
      text: "An unexpected error occurred. Please try again later.",
      icon: "error",
    });
      }
  
      console.log("Forgot password failed:", error);
    }
  },
  async updatePassword() {
    if (!this.validatePassword()) {
        return;
      }
    try {
      const response = await axios.put(
        `https://api.qurangpt.com/api/v1/user/update-password/`,
        {
          username: this.username,
          ip_address: this.deviceInfo.ip,
          location: `${this.deviceInfo.location.city}, ${this.deviceInfo.location.region}, ${this.deviceInfo.location.country}`,
          device: this.deviceInfo.device,
          password: this.password,
        }
      );
  console.log(response);
      if (response.status === 200) {
        swal({
      title: "Success",
      text: "Password updated, please login",
      icon: "success",
    });
        this.passwordshow = false;
        this.inputAttributes.readonly = false;
  this.contact='';
  this.password='';
        this.$router.push('/');
      } else {
        swal({
      title: "Success",
      text: response.data.message,
      icon: "success",
    });
      }
  
      console.log("Password update successful:", response);
    } catch (error) {
        swal({
      title: "error",
      text: "An unexpected error occurred. Please try again later.",
      icon: "error",
    });
      
      console.log("Password update failed:", error);
    }
  }
    },
  };
  </script>
  
  <style scoped></style>
  