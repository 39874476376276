<template>
  <div id="wrapper">
    <div class="container">
      <div class="row justify-content-center align-items-center py-5">
        <div class="col-sm-10 col-md-10 text-center">
          <SearchBar hide_logo="true" hide_search="true"/>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <nav aria-label="breadcrumb" class="text-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name:'Landing' }">Rehal</router-link>
        </li>        
        <li class="breadcrumb-item active" aria-current="page">Chapters</li>
      </ol>
    </nav>
  </div>

  <div class="wrapper_bg">
    <div class="container mt-3">
      <div class="mb-5 text-center text-md-start">
        <h1 class="headline_3 ">All Chapters</h1>
      </div>
      <div class="row g-3">
        <div class="col-6 col-md-4 col-lg-3" v-for="index in 30" :key="index">
          <div>
            <div class="card cover_card text-center p-3 p-md-4">
              <div class="card-body py-3 py-md-5">
                <div class="card-number">{{ index }}</div>
                <h1>Chapter (Juz)</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  <div class="bg-white">
    <FooterVue></FooterVue>
  </div>
</template>

<script>
import axios from 'axios';

import Logo from '@/assets/logo-quran-gpt.png'

import SearchBar from '@/components/SearchBar.vue';
import FooterVue from '@/components/Footer.vue';


import { useHead } from "@vueuse/head";
// import axios from 'axios';
export default {
    name: 'RihaalView',
    data() {
      return {
        logo: Logo,
        edition_name : this.$store.getters.getEdition,
        loading: true
      };
    },
    setup() {      
      useHead({
          title: 'Chapters of Quran - Quran GPT',
          meta: [
            {
              name: 'description',
              content: 'The Quran is divided into Surahs (chapters) and further divided into Ayahs (verses). The real translation of the word Ayah is Sign of Allah',
            },
          ],
      })
    },  
    mounted() {
      if(this.$store.getters.isAuthenticated == false)
      {
        this.$swal.fire({
          title: "<strong>Kindly log in to proceed.</strong>",
          icon: "info",
          html: `           
            For full access to all features, please log in or register.
            <br>
            <br>
            <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" class="button">Login</a>
          `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          showConfirmButton: false,
        });
      }
      this.getCategory()
    },
    components: {
      SearchBar,
      FooterVue
    },
    methods:{
      async getCategory()
      {
        try {
          const response = await axios.get(`https://api.qurangpt.com/api/v1/user/get_dua_category/`);
          this.language_list = response.data.data;
          this.loading = false;
        } catch (error) {
          console.error('Error fetching data:', error);
          this.loading = false;
        }
      },
    }
}
</script>
