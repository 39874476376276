import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// meta tags manager
import { createHead } from '@vueuse/head'

import './registerServiceWorker'
const useHead = createHead()
// meta tags manager

// sweet alert
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
// sweet alert


// Get the stored data from local storage
const storedData = localStorage.getItem('qurangptstorageunit');
if (storedData) {
  store.replaceState(JSON.parse(storedData));
}



// non www to www redirect
router.beforeEach((to, from, next) => {
    // Continue with the navigation
    // alert(to.fullPath);
    // alert(to.hostname);
    // alert(location.host);
    // alert(location.toString());
    if(!/\.?www./g.test(location.toString())) {
      location.href = location.href.replace("://","://www.")
    }
    next();
});



const app = createApp(App)
app.use(store)
app.use(useHead)
app.use(router)
app.use(VueSweetalert2);

// google login
import vue3GoogleLogin from 'vue3-google-login'
app.use(vue3GoogleLogin, {
  clientId: '521856797844-ho0fu8k17urpnma6dcsmpoamgf3chtf4.apps.googleusercontent.com'
})
// google login


app.mount('#app')