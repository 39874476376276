<template>
  <div id="wrapper">
    <div class="container">
      <div class="row justify-content-center align-items-center py-5">
        <div class="col-sm-10 col-md-10 text-center">
          <SearchBar />
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <nav aria-label="breadcrumb" class="text-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name:'Landing' }">Rehal</router-link>
        </li>
        
        <li class="breadcrumb-item active" aria-current="page">Chapters</li>
      </ol>
    </nav>
  </div>

  <div class="wrapper_bg">
    <RecentChapter></RecentChapter>
    <div class="container mt-3">
      <div class="mb-5 text-center text-md-start">
        <h1 class="headline_3 ">All Chapters</h1>
      </div>
      <div class="row g-3">
        <div class="col-md-4 col-lg-3" v-for="index in 30" :key="index">
          <router-link :to="'/chapter/details/' + index" class="text-decoration-none card_page_wrapper">
            <div class="card cover_card text-center ">
              <div class="card-body py-5">
                <div class="card-number">{{ index }}</div>

                <h1>Chapter (Juz)</h1>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      
    </div>
  </div>
  <div class="bg-white">
    <FooterVue></FooterVue>
  </div>
</template>

<script>
import RecentChapter from '@/components/RecentChapter.vue';
import Logo from '@/assets/logo-quran-gpt.png'

import SearchBar from '@/components/SearchBar.vue';
import FooterVue from '@/components/Footer.vue';


import { useHead } from "@vueuse/head";
// import axios from 'axios';
export default {
    name: 'RihaalView',
    data() {
      return {
        logo: Logo,
        edition_name : this.$store.getters.getEdition,
        loading: true
      };
    },
    setup() {      
      useHead({
          title: 'Chapters of Quran - Quran GPT',
          meta: [
            {
              name: 'description',
              content: 'The Quran is divided into Surahs (chapters) and further divided into Ayahs (verses). The real translation of the word Ayah is Sign of Allah',
            },
          ],
      })
    },  
    mounted() {
    },
    components: {
      SearchBar,
      RecentChapter,
      FooterVue
    }
}
</script>
