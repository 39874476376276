<template>
  <div id="wrapper">
    <div class="container">
      <div class="row justify-content-center align-items-center py-5">
        <div class="col-sm-10 col-md-10 text-center">
          <SearchBar hide_logo="true" hide_search="true"/>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <nav aria-label="breadcrumb" class="text-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name:'Landing' }">Rehal</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Surah</li>
      </ol>
    </nav>
  </div>
  <div class="wrapper_bg">
    <RecentSurah></RecentSurah>

    <div class="container mt-3 " style="min-height: 100vh;">
      <div class="mb-5 text-center text-md-start">
        <h1 class="headline_3 ">All Surahs</h1>
      </div>
      <div v-if="loading">
        <div class="row justify-content-md-center">
          <div class="col-md-4">
            <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="height: 30px">
              <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" style="width: 100%">Loading</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3" v-else>
        <div class="col-6 col-md-6 col-lg-3 " v-for="item in fetchedData" :key="item.nummber">
          <router-link :to="'/surah/'+ generateSlug(item.english_name) + '/' + item.number" class="text-decoration-none card_page_wrapper">
            <div class="card cover_card text-center p-3 p-md-4">
              <div class="card-body py-3 py-md-5">
                <div class="card-number">{{ item.number }}</div>

                <h1>{{ item.english_name }}</h1>
                <h2>{{ item.english_name_translation }}</h2>
              
                <p class="d-block">Ayahs : {{ item.number_of_ayahs }}</p>
              
                Revelation : : {{ item.revelation_type }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      
    </div>
  </div>  
  <div class="bg-white">
  <FooterVue></FooterVue>
  </div>
</template>

<script>
import RecentSurah from '@/components/RecentSurah.vue';
import Logo from '@/assets/logo-quran-gpt.png'

import SearchBar from '@/components/SearchBar.vue';
import FooterVue from '@/components/Footer.vue';

import { useHead } from "@vueuse/head";

import axios from 'axios';
export default {
    name: 'RihaalView',
    data() {
      return {
        logo: Logo,
        fetchedData: [],
        loading: true
      };
    },
    setup() {      
      useHead({
          title: 'Surah of Quran - Quran GPT',
          meta: [
            {
              name: 'description',
              content: 'The Quran is divided into 114 Surahs and further divided into Ayahs (verses). The real translation of the word Ayah is Sign of Allah',
            },
          ],
      })
    },  
    mounted() {
      this.fetchData();
      if(this.$store.getters.isAuthenticated == false)
      {
        this.$swal.fire({
          title: "<strong>Kindly log in to proceed.</strong>",
          icon: "info",
          html: `           
            For full access to all features, please log in or register.
            <br>
            <br>
            <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" class="button">Login</a>
          `,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          showConfirmButton: false,
        });
      }
    },
    components: {
      RecentSurah,
      SearchBar,
      FooterVue
    },
    methods: {
      // async fetchData() {
      //   try {
      //     const response = await axios.get('https://api.alquran.cloud/v1/surah');
      //     this.fetchedData = response.data;
      //     if((response.data.data).length > 0)
      //     {
      //       this.loading = false;
      //     }
      //   } catch (error) {
      //     console.error('Error fetching data:', error);
      //   }
      // },
      async fetchData() {
        try {
          const response = await axios.get('https://api.qurangpt.com/api/v1/user/quran/surahs/');
          this.fetchedData = response.data.data;
          console.log('this.fetchedData',this.fetchedData);
          if((this.fetchedData).length > 0)
          {
            this.loading = false;
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      },
      generateSlug(title) {
        // Convert title to lowercase and replace spaces with hyphens
        return title.toLowerCase().replace(/\s+/g, '-');
      }
    }
}
</script>
