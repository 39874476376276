<template>
  <main>
    <div class="card h-100 page_card">
      <div class="card-header p-0">
        <div class="container-xxl">
          <div class="row py-2 g-0">
            <div class="col col-md">
              <router-link class="button py-2 px-2 px-md-4" :to="{ name: 'Landing' }"><span><i
                    class="bi bi-chevron-left"></i> <span
                    class="d-none d-md-inline-block">Back</span></span></router-link>
            </div>
            <div class="col-6 col-md">
              <div class="d-flex justify-content-center">
                <div class="d-none d-md-inline-block">
                  <div class="card-number text-center mb-0 me-2">{{ fetchedData.number }}</div>
                </div>
                <div class="text-center text-md-start">
                  <h3 class="mb-0 mt-1 h6">{{ fetchedData.englishName }}</h3>
                  <small>{{ fetchedData.englishNameTranslation }} <span class="badge bg-danger" title="No. Ayahs"><span
                        class="d-none d-md-inline-block">No. Ayahs</span> {{ fetchedData.numberOfAyahs }}
                    </span></small>
                </div>
              </div>
            </div>
            <div class="col col-md text-end">
              <button class="button py-2 px-3" type="button" @click="showOffcanvas = true">
                <i class="bi bi-translate"></i> <span class="d-none d-md-inline-block">Change Editions</span>
              </button>
            </div>
          </div>
        </div>
        <TextToSpeech ref="textReader"></TextToSpeech>
      </div>
      <div class="card-body overflow-y-scroll p-0">
        <div class="container-xxl">
          <div v-if="loading">
            <div class="row justify-content-md-center my-5 py-5">
              <div class="col-md-4">
                <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100"
                  aria-valuemin="0" aria-valuemax="100" style="height: 30px">
                  <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" style="width: 100%">
                    Loading</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="d-flex justify-content-center py-2 justify-content-between align-items-center">
              <nav aria-label="breadcrumb" class="text-center d-none d-md-block">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Landing' }">Rehal</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Surah' }">Surahs</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Surah ~ {{ fetchedData.englishName }}</li>
                </ol>
              </nav>
              <div class="form-check form-switch">
                <input class="form-check-input" v-model="reading_mode" type="checkbox" role="switch"
                  id="flexSwitchCheckDefault">
                <label class="form-check-label" for="flexSwitchCheckDefault">Reading Mode</label>
              </div>
            </div>
            <div class="row g-3">
              <div class="ayat_card p-0" v-if="reading_mode">
                <div class="card-header d-flex justify-content-between align-items-center">
                  <div class="">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                        v-model="reading_mode_feature.arabic_text">
                      <label class="form-check-label" for="inlineCheckbox1">Arabic</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
                        v-model="reading_mode_feature.arabic_text_trans">
                      <label class="form-check-label" for="inlineCheckbox2">Transliteration</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox3"
                        v-model="reading_mode_feature.meaning_text">
                      <label class="form-check-label" for="inlineCheckbox3">Meaning</label>
                    </div>
                  </div>
                  <button class="play_button"
                    @click="playVoice('https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/' + fetchedData.number + '.mp3')">
                    <i class="bi bi-volume-up"></i> Listening
                  </button>

                </div>
                <div class="card-body">
                  <div
                    v-if="reading_mode_feature.arabic_text != false || reading_mode_feature.arabic_text_trans != false || reading_mode_feature.meaning_text != false">
                    <div class="mb-3 text-center" v-for="(ayah, index) in fetchedData.ayahs" :key="index">
                      <p class="font-family-arabic arabic-line  text-end" v-if="reading_mode_feature.arabic_text">
                        {{ ayah.quran_text }}</p>
                      <p class="m-0 p-0 text-secondary text-end mb-3" v-if="reading_mode_feature.arabic_text_trans">
                        {{ ayah.transliteration }}</p>
                      <div v-if="reading_mode_feature.meaning_text">
                        <p :class="fontfam" class="text-link text-center h4">
                          {{ ayah.text }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mb-3 text-center" v-for="item in fetchedData.ayahs" :key="item">
                      <p class="font-family-arabic arabic-line  text-end">{{ item.quran_text }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-start" v-else v-for="(ayah, index) in fetchedData.ayahs" :key="index">
                <div class="ayat_card">
                  <div class="card-header">
                    <div class="d-flex justify-content-between">
                      <div>
                        <badge>Ayat: {{ ayah.number }}</badge>
                      </div>
                      <div>
                        Surah Ayat No: {{ ayah.numberInSurah }}
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="card-body">
                    <div class="row g-3">
                      <div class="col-12 col-md-12 text-center ">
                        <div class="border-bottom mb-3 pb-2">
                          <h1 class="font-family-arabic arabic-line">{{ ayah.quran_text }}</h1>
                          <i class="m-0 p-0 text-secondary">{{ ayah.transliteration }}</i>
                        </div>
                        <p :class="fontfam" class="text-link text-center h4">
                          {{ ayah.text }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-6 col-md-6">
                        <div class="text-start">
                          <button class="play_button" @click="readText(ayah.text, fontfam)">
                            <i class="bi bi-volume-up"></i> Meaning</button>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="text-end">
                          <button class="play_button"
                            @click="playVoice('https://cdn.islamic.network/quran/audio/64/ar.alafasy/' + ayah.number + '.mp3')"><i class="bi bi-volume-up"></i> Arabic
                          </button>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer shadow">

        <div class="container-xxl">
          <div class="d-flex flex-column flex-md-row gap-3 align-items-center justify-content-between">
            <div class="d-flex gap-3 justify-content-center w-100 text-center text-md-end">
              <a class="button py-2 px-2 px-md-4" @click="prevPage()" v-if="this.surah_id > 1"><span><i
                    class="bi bi-chevron-left"></i> <span>Prev</span></span></a>
              <a class="button py-2 px-2 px-md-4" @click="nextPage()" v-if="this.surah_id < 114"><span><span>Next</span>
                  <i class="bi bi-chevron-right"></i></span></a>
            </div>
          </div>

        </div>
      </div>
    </div>


  </main>
  <div class="offcanvas offcanvas-bottom" :class="{ 'show': showOffcanvas }" tabindex="-1" id="editionModal"
    aria-labelledby="editionModalLabel">
    <div class="offcanvas-header bg-light">
      <h5 class="offcanvas-title text-center" id="editionModalLabel">Change Edition: </h5>
      <button type="button" class="btn-close" @click="showOffcanvas = false"></button>
    </div>
    <div class="offcanvas-body small">
      <div class="container-xxl">
        <form class="row g-3" @submit.prevent="fetchData()" v-if="$store.getters.isAuthenticated">
          <div class="col-md">
            <label>Language</label>
            <select class="form-control" v-model="filter_edition.language" @change="fetchEditionData()" required>
              <option value="all">All</option>
              <option v-for="item in language_list" :key="item.language_code" :value="item.language_code">{{item.language_name }}</option>
            </select>
          </div>

          <div class="col-md">
            <label>Editions: <span class="badge bg-danger">{{ edition_list.length }}</span></label>
            <select :disabled="edition_list.length === 0" class="form-control text-capitalize" v-model="edition_name" @change="changeEdition()" required>
              <option v-for="item in edition_list" :key="item" :value="item.identifier">{{ item.english_name }}</option>
            </select>
          </div>
          <div class="col-md-12 text-center">
            <button type="submit" class="button button py-2 px-3">Submit</button>
          </div>
        </form>
        <div v-else class="text-center">
          <a @click="menuToggle = false" data-bs-toggle="modal" data-bs-target="#authModal"
            class="button py-2 px-2 px-md-5"><i class="bi bi-person"></i> Login</a>
        </div>
      </div>


    </div>
  </div>
</template>



<script>

import axios from 'axios';
import Swal from 'sweetalert2'
import TextToSpeech from '@/components/TextToSpeech.vue';
import { useHead } from "@vueuse/head";

export default {
  name: 'RihaalView',
  components: {
    TextToSpeech
  },
  props: {
    surah_id: String,
    surah_title: String,
  },
  data() {
    return {
      showOffcanvas: false,
      language_list: [],
      fetchedData: [],
      fontfam: '',
      fetchedTranslateData: [],
      edition_list: [],
      surah_number: this.surah_id,
      surah_name: this.surah_title,
      filter_edition: {
        language: "all",
      },

      edition_name: this.$store.getters.getEdition,
      loading: true,
      play_voice: "",
      read_text: "",
      read_language: "",
      reading_mode: false,
      reading_mode_feature: {
        arabic_text: true,
        arabic_text_trans: false,
        meaning_text: false
      }
    };
  },
  setup() {
    useHead({
      title: `Surah - Quran GPT`,
      meta: [
        {
          name: 'description',
          content: 'The Quran is divided into 114 Surahs and further divided into Ayahs (verses). The real translation of the word Ayah is Sign of Allah',
        },
      ],
    })
  },
  unmounted() {
    location.reload();
  },

  mounted() {
    this.fetchData();
    this.fetchEditionData();
    this.fetchLanguagesData();

    if (this.$store.getters.isAuthenticated == false) {
      this.$swal.fire({
        title: "<strong>Kindly log in to proceed.</strong>",
        icon: "info",
        html: `           
            For full access to all features, please log in or register.
            <br>
            <br>
            <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" class="button">Login</a>
          `,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showConfirmButton: false,
      });
    }
  },
  methods: {
    isLanguageSupported(language) {
      const voices = speechSynthesis.getVoices();
      return voices.some(voice => voice.lang === language);
    },
    playVoice(url) {
      this.play_voice = url;
      // this.$refs.controlPlayer.play(); // Call the child component's function
      this.$refs.textReader.readText("text", "language", this.play_voice);
    },
    readText(text, language) {
      this.$refs.textReader.readText(text, language); // Call the child component's function
    },
    pageback() {
      this.surah_number = parseInt(this.surah_id) - 1
      this.$router.go(-1);
      this.fetchData();
    },
    async fetchData() {
      this.showOffcanvas = false;
      this.loading = true;
      try {
        const response = await axios.get(`https://api.qurangpt.com/api/v1/user/quran/ayahs-by-surah/${this.surah_number}/${this.edition_name}/`);
        this.fetchedData = response.data.data;
        this.fontfam = response.data.edition.language;
        this.addAsRecentViewSurah(response.data.data);
        this.loading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.loading = false;
      }
    },
    async fetchEditionData() {
      try {
        const response = await axios.get(`https://api.qurangpt.com/api/v1/user/quran/editions/${this.filter_edition.language}/`);
          this.edition_list = response.data.data;
      } catch (error) {
        Swal.fire({
          title: 'Sorry',
          text: 'Editions not found on this selection',
          icon: 'error',
          showConfirmButton: false,
        })
      }
    },
    async fetchLanguagesData() {
      try {
        const response = await axios.get(`https://api.qurangpt.com/api/v1/user/quran/languages/`);
        this.language_list = response.data.data;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.loading = false;
      }
    },
    changeEdition() {
      this.$store.commit('SET_EDITIONAL', this.edition_name);
    },


    prevPage() {
      this.surah_number = parseInt(this.surah_id) - 1
      this.$router.push({ name: 'SurahDetailsView', params: { surah_id: this.surah_number } })
      this.fetchData();
    },
    nextPage() {
      this.surah_number = parseInt(this.surah_id) + 1
      this.$router.push({ name: 'SurahDetailsView', params: { surah_id: this.surah_number } })
      this.fetchData();
    },


    addAsRecentViewSurah(fetchedData) {
      let getRgetRSurahs = this.$store.getters.getRSurahs
      if (getRgetRSurahs.filter(item => item.number == fetchedData.number).length == 0) {
        this.$store.commit('SET_R_SURAHS', fetchedData);
      }
    }
  }
}
</script>