<template>
    <!-- <h1 class="headline_1 mb-5">Quran <span>GPT</span></h1> -->
    <!-- <div v-if="hide_logo != 'true'"><img :src=logo class="w-50 mb-3 brandLogo py-5"></div> -->

    <div class="d-none d-md-block"><img :src=logo class="w-50 mt-5"></div>
    <!-- <div v-else><img :src=logo class="w-50 mt-5"></div> -->
    <div v-if="hide_search != 'true'">
        <div role="search" id="searchWrapper" class="pt-5 mt-2">
          <form @submit.prevent="fetchStreamedText" class="d-flex gap-3">
            <input list="suggetions" class="form-control search_input" type="search" autocomplete="off" @keyup="getKeywordSuggestions()" autofocus placeholder="Search" aria-label="Search" v-model="question" required>
            <button class="btn btn-light search_button" type="submit"><i class="bi bi-search"></i></button>
          </form>      
          <datalist id="suggetions"><option :value="suggestion" v-for="suggestion in search_keyword_suggesion" :key="suggestion">{{suggestion}}</option></datalist>

          <div v-if="loading">
            <p class="placeholder-glow">
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
            </p>
            <p class="placeholder-glow">
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
            </p>
          </div>
          <div v-if="answer != ''">
            <div class="mb-2" >
                <div class="card card-body text-start my-3">
                    <div class="text-body-tertiary mb-0" v-html="answer.replace(/\n/g, '<br />')"></div>
                </div>
            </div>
          </div>        
          <p class="py-3">
            Ask us anything about Quran and Islam is coming soon<br>
            <small class="text-secondary">Free Research Preview. Quran GPT may produce inaccurate information about people, places, or facts. </small>
          </p>      
        </div>
    </div>

    <div class="d-flex flex-column flex-md-row gap-3 justify-content-center mt-5">
      <router-link class="button" :to="{ name:'Chapter' }">CHAPTER</router-link>
      <router-link class="button button_outline" :to="{ name:'Surah' }">SURAH</router-link>
      <router-link class="button" :to="{ name:'Editions' }">EDITIONS</router-link>
    </div>
  </template>  
  <script>
  import Logo from '@/assets/logo-quran-gpt.png'
  import axios from 'axios';


  export default {
    name: 'SearchBar',
    data() {
      return {
        logo: Logo,
        loading : false,
        apiKey: process.env.VUE_APP_OPEN_AI_API_KEY,
        question: "",
        answer: "",
        gpt_task: false,
        search_keyword_suggesion: [],
      };
    },
    props: ["hide_logo",'hide_search'],
    methods: {
      getKeywordSuggestions()
      {
          if(this.question != "")
          {
            const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/gpt/search/suggestions/${this.question}/`;
              axios.get(API_URL)
              .then((response) => {
                  if(response.status == 204)
                  {
                      // this.$toast.error(response.data.message, {
                      //     icon: "dangerous",
                      // }); 
                  }
                  else if(response.status == 200)
                  {
                      this.search_keyword_suggesion = response.data.suggestion
                  }

              })
              .catch((error) => {
              // Handle errors
                  console.log(error);
                  this.$toast.error("Ops something is wrong. try again later", {
                      icon: "dangerous",
                  }); 
                  this.loading = false;
              });
          }
      },
      async fetchStreamedText()
      {
        if(this.$store.getters.isAuthenticated)
        {
          this.answer = "";
          this.loading = true
          this.gpt_task = false
          const streamUrl = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/gpt/campaign?prompt=${this.question}`;
          
          await fetch(streamUrl).then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              this.loading = false
              const reader = response.body.getReader();
              const streamDecoder = new TextDecoder();
              const processStream = ({ done, value }) => {
                  if (done) {
                      console.log('Stream complete');
                      this.gpt_task = true;
                      return;
                  }
                  const chunk = streamDecoder.decode(value, { stream: true });
                  this.answer += chunk;
                  return reader.read().then(processStream);
              };
              return reader.read().then(processStream);
          }).catch(error => {
                  console.error('Fetch failed:', error);
          });
        }
        else
        {
          this.$swal.fire({
            title: "<strong>Kindly log in to proceed.</strong>",
            icon: "info",
            html: `           
              For full access to all features, please log in or register.
              <br>
              <br>
              <a href="#" data-bs-toggle="modal" data-bs-target="#authModal" class="button">Login</a>
            `,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            showConfirmButton: false,
          });
        }
      },
      searchAnswer()
      {
          if(this.question != "")
          {
              this.answer = '';
              this.loading = true;
              // calling api
              const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/search/`;
              const formData = axios.toFormData({
                  'question' : this.question
              })
              
              axios.post(API_URL, formData)
              .then((response) => {
                  if(response.status == 204)
                  {
                      this.$toast.error(response.data.message, {
                          icon: "dangerous",
                      }); 
                  }
                  else if(response.status == 200)
                  {
                      // this.getConversations()
                      this.answer = response.data.data.response_gpt;
                  }
                  console.log(response.data);
                  this.loading = false;
              })
              .catch((error) => {
              // Handle errors
                  console.log(error);
                  this.$toast.error("Ops something is wrong. try again later", {
                      icon: "dangerous",
                  }); 
                  this.loading = false;
              }); 
          }
          // calling api
      },

      searching()
      {
          if(this.question != undefined)
          {
              this.loading = true;
              this.searchAnswer();
          }
      },
    },
  };
  </script>
  

<style scoped>
@media only screen and (max-width: 600px) {
  .brandLogo {
    display: none;
  }
}
</style>
  