<template>
    <main class="mt-5 pt-5">
        <div class="container">
            <h1 class="headline_1 mb-5 text-center">Privacy Policy for Qurangpt.com</h1>

            <div class="card card-body p-4">

                <p>
                    At Qurangpt.com, the privacy of our visitors is of utmost importance to us. This Privacy Policy document outlines the types of personal information received and collected by Qurangpt.com and how it is used.
                </p>

                <h1 class="headline_3 mb-0">Information Collection and Use</h1>
                <p>
                    Qurangpt.com may collect personal information, such as names and email addresses, voluntarily submitted by users when signing up for our services or newsletter. This information is used solely for the purpose of enhancing user experience, providing requested services, and keeping users informed about updates and relevant content.
                </p>
                

                <h1 class="headline_3 mb-0">Log Files</h1>
                <p>
                    Qurangpt.com follows a standard procedure of using log files. These files log visitors when they visit the website. Log files may include internet protocol (IP) addresses, browser type, internet service provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used to analyze trends, administer the site, track user movement around the site, and gather demographic information.
                </p>
                
                <h1 class="headline_3 mb-0">Cookies and Web Beacons</h1>
                <p>
                    Qurangpt.com uses cookies to store information about visitors' preferences, to record user-specific information on which pages the user accesses or visits, and to personalize or customize our web page content based on visitors' browser type or other information that the visitor sends via their browser.
                </p>

                <h1 class="headline_3 mb-0">Third-Party Links</h1>
                <p>
                    Qurangpt.com may contain links to other websites that are not operated by us. Please note that we have no control over the content and practices of these sites and cannot be responsible for the protection and privacy of any information you provide while visiting such sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
                </p>

                <h1 class="headline_3 mb-0">Data Security</h1>
                <p>
                    Qurangpt.com implements reasonable security measures to maintain the safety of users' personal information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
                </p>

                <h1 class="headline_3 mb-0">Consent</h1>
                <p>
                    By using Qurangpt.com, you hereby consent to our Privacy Policy and agree to its terms.
                </p>

                <h1 class="headline_3 mb-0">Updates</h1>
                <p>
                    This Privacy Policy may be updated periodically. Users are encouraged to review this page for any changes. Your continued use of the site after any changes to this Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the updated Privacy Policy.
                </p>
                <p>
                    If you have any questions or require more information about our Privacy Policy, please contact us at [qurangpt@9xtechnology.com].
                    This Privacy Policy was last updated on 29/12/2023. 
                </p>

            </div>
        </div>
    </main>
</template>

<script>
    import { useHead } from "@vueuse/head";
    export default{
        setup()
        {
            useHead({
                title: 'Privacy Policy - Quran GPT'
            })
        },
    }
</script>