
<template>
  <div id="app" @scroll="handleScroll">
      
    <!-- Other content of your app -->
    <nav class="navbar navbar-expand-lg fixed-top" :class="{ 'sticky-class': isSticky }">
      <div class="container">
        <router-link :to="{ name:'Landing' }" class="navbar-brand" href="#">
          <img :src=logo height="40" class="d-block" v-if="isSticky">

        </router-link>
        <div class="d-flex gap-2" @click="profileCanvas = true">
          <div v-if="$store.getters.isAuthenticated">
            <button class="mobile_menu">
              <i class="bi bi-person-circle"></i>
            </button>
          </div>
          <button class="mobile_menu">
            <i class="bi bi-list"></i>
          </button>
        </div>    
      </div>
    </nav>
  
    <video width="320" height="240" autoplay muted loop :poster=BGVideoCover>
      <source :src="BGVideo" type="video/mp4">
    </video>
  
    <router-view/>
    <!-- <TextToSpeech></TextToSpeech> -->
  
    <!-- Modal -->
    <div class="modal fade" id="authModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="authModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <!-- Close button with Bootstrap attributes -->
              <button type="button" class="btn-close" ref="closeButton" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">
              <!-- Auth component to be displayed in modal body -->
              <Auth />
            </div>
          </div>
        </div>
      </div>
  
  
    <div class="profile_offcanvas shadow-lg" v-if="profileCanvas == true">
      <div class="header">
        <h5 class="offcanvas-title" id="profileCanvasLabel"></h5>
        <button type="button" class="btn-close"  @click="closeOffcanvas"></button>
      </div>
      <div class="body">
        <div class="text-center">
          <div v-if="$store.getters.isAuthenticated">
            <i class="bi bi-person-circle display-1"></i>
            <hr>
            <h5 class="mb-0 mt-0">{{ $store.getters.getUser.first_name }} {{ $store.getters.getUser.last_name }}</h5>
            <p class="mb-0 text-secondary small">{{ $store.getters.getUser.username }}</p>
            <a class="button py-2 px-2 mt-3 px-md-5 w-100" @click="logout()">
              LOGOUT <i class="bi bi-box-arrow-right"></i>
            </a>
            <hr>
          </div>
          <div v-else>
            <i class="bi bi-person-circle display-1"></i>
            <hr>
            <a href="#" @click="closeOffcanvas" data-bs-toggle="modal" data-bs-target="#authModal" class="fw-bold text-decoration-none text-dark">Login</a>
            <hr>
          </div>
          
          <div class="d-flex flex-column gap-3 justify-content-center">
            <router-link @click="closeOffcanvas" class="button py-2 px-2 px-md-5" :to="{ name:'Landing' }">HOME</router-link>
            <router-link @click="closeOffcanvas" class="button py-2 px-2 px-md-5" :to="{ name:'Chapter' }">CHAPTER</router-link>
            <router-link @click="closeOffcanvas" class="button py-2 px-2 px-md-5 button_outline" :to="{ name:'Surah' }">SURAH</router-link>
            <router-link @click="closeOffcanvas" class="button py-2 px-2 px-md-5" :to="{ name:'Editions' }">EDITIONS</router-link>
            <router-link @click="closeOffcanvas" class="button py-2 px-2 px-md-5" :to="{ name:'DownloadAppPage' }">QIBLA DIRECTION</router-link>
            <router-link @click="closeOffcanvas" class="button py-2 px-2 px-md-5" :to="{ name:'DownloadAppPage' }">PRAYER TIMES</router-link>
            <router-link @click="closeOffcanvas" class="button py-2 px-2 px-md-5" :to="{ name:'DownloadAppPage' }">RAMADAN TRACKER</router-link>
          </div>
          <hr>
          <div class="list-group text-start">
            <router-link @click="closeOffcanvas" :to="{ name:'AboutUsVue' }" class="list-group-item list-group-item-action" href="#"><i class="bi bi-chevron-right"></i> About us</router-link>
            <router-link @click="closeOffcanvas" :to="{ name:'PrivacyPolicy' }" class="list-group-item list-group-item-action" href="#"><i class="bi bi-chevron-right"></i> Privacy Policy</router-link>
            <router-link @click="closeOffcanvas" :to="{ name:'TermsAndConditionsVue' }" class="list-group-item list-group-item-action" href="#"><i class="bi bi-chevron-right"></i> Terms and Conditions</router-link>
            <a class="list-group-item list-group-item-action" target="_blank" href="https://9xtechnology.com/contact.php"><i class="bi bi-chevron-right"></i> Contact us</a>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  </template>
  
  <script>
  import { useHead } from "@vueuse/head";
  // import Logo from '@/assets/logo-quran-gpt.png'
  import Auth from './views/auth/AuthView.vue'
  import BGVideo from '@/assets/bg.mp4';
  import BGVideoCover from '@/assets/video-cover.jpg';
  import { ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import Logo from '@/assets/logo-quran-gpt.png';
  // import TextToSpeech from "./components/TextToSpeech.vue";
  
  export default{
    name : "Quran GPT",
    components:{
      Auth
    },
    data() {
      return {
        logo: Logo,
        menuToggle: false,
        BGVideo: BGVideo,
        BGVideoCover: BGVideoCover,
        isSticky: false,
        profileCanvas: false
      };
    },
    setup() {      
      const route = useRoute();
      const closeButton = ref(null);
      watch(
        () => route.path,
        (newPath) => {
          if (newPath === "/forgot-password") {
            closeButton.value?.click();
          }
        }
      );
      
      useHead({
          title: 'Welcome to Quran GPT - The encyclopedia of life',
          meta: [
            {
              name: 'description',
              content: 'It was sent down by Allah, through his angel Jabreel, who revealed the 114 chapters over the course of 23 years to Prophet Muhammad, may peace be upon him. It was his life’s mission to deliver this message to mankind and help guide and save them.',
            },
          ],
      })
      return {
        closeButton,
      };
    },  
    created(){
      if (this.isMobile()) {
        this.isSticky = true
      }
    },
    methods: {
      isMobile() {
          return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      },
      closeOffcanvas() {
        this.profileCanvas = false
      },
      handleScroll(event) {
        if (!this.isMobile()) {
          // Calculate the scroll position
          const scrollPosition = event.target.scrollTop;
  
          // Define a threshold for when the class should be added or removed
          const threshold = 100; // Adjust this value as needed
  
          // Add or remove the class based on the scroll position
  
          this.isSticky = scrollPosition >= threshold;
        }
      },
      logout() {
          // this.$router.push({ name: 'LoginView' })
          this.$store.dispatch('logout') 
          location.reload()
      },
    },
  }
  </script>
  
  <style>
    .modal 
    {
      z-index: 100000000000000000 !important;
    }
   .show {
      display: block !important;
    }
  
    .form-check-input:checked {
        background-color: #CE8610 !important;
        border-color: #CE8610 !important;
    }
  
  
    @import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
  
  
    /* font family arabic */
    @font-face {
      font-family: arabicFont;
      src: url('./assets/font/AlQalamQuran.ttf');
    }
    @font-face {
      font-family: urduFont;
      src: url('./assets/font/NotoNastaliqUrdu.ttf');
    }
    @font-face {
      font-family: tamilFont;
      src: url('./assets/font/NotoSansTamil.ttf');
    }
    @font-face {
      font-family: malayalamFont;
      src: url('./assets/font/NotoSansMalayalam.ttf');
    }
    @font-face {
      font-family: bengaliFont;
      src: url('./assets/font/NotoSerifBengali.ttf');
    }
    @font-face {
      font-family: hindiFont;
      src: url('./assets/font/TiroDevanagariHindi.ttf');
    }
    @font-face {
      font-family: japanFont;
      src: url('./assets/font/NotoSerifJapan.otf');
    }
    
    .lh-none
    {
      line-height: normal !important;
    }
    .font-family-arabic{
      font-family: arabicFont;
      line-height: 40px;
    }
    .ta{
      font-family: tamilFont;
      line-height: 40px;
    }
    .ja{
      font-family: japanFont;
      line-height: 40px;
    }
    .bn{
      font-family:bengaliFont;
      line-height: 40px;
  
    }
    .hi{
      font-family:hindiFont;
      line-height: 40px;
  
    }
    .ml{
      font-family:malayalamFont;
      line-height: 40px;
  
    }
    .ar,.ps,.ug, .sd, .ku, .dv, .fa,.th{
      font-family: arabicFont;
      line-height: 40px;
    }
    .ar,.bl,.ko{
      font-family: urduFont;
      line-height: 40px;
    }
    .font-family-tamil{
      font-family: tamilFont;
    }
    .font-family-malayalam{
      font-family: malayalamFont;
    }
    .font-family-bangal{
      font-family: bengaliFont;
    }
    .font-family-hindi{
      font-family: hindiFont;
    }
    .font-family-japan{
      font-family: japanFont;
    }
  
    .arabic-line
    {
      font-size: clamp(38px, 4vw, 45px);
      line-height: 60px;
    }
    
    
    /* font family arabic */
  
    #app
    {
      position: relative;
    }
    #app video 
    {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: fill;
      z-index: -2;
    }
    #app::before
    {
      content: "";
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background: rgba(255,255,255,0.6);
    }
  
    #drop_menu_box
    {
      background: white;
      min-height: 100px;
      max-width: 300px;
      position: absolute;
      right: 20px;
      top: 60px;
      border-radius: 10px;
      padding: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    #drop_menu_box a{
      cursor: pointer;
    }
  
    .navbar
    {
      z-index: 10;
    }
    #app
    {
      height: 100vh;
      overflow: auto;
    }
    .sticky-class {
      background-color: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      /* Additional styling for sticky state */
    }
    /* body 
    {
      background: url('assets/bg.jpg') fixed center no-repeat;
      background-size:cover !important;
      animation: color 20s infinite linear;
    } */
    /* @keyframes color {
      0%   {
        background: url('assets/bg.jpg') fixed center no-repeat;
      }
      50%   {
        background: url('assets/bg.jpg') fixed bottom no-repeat;
      }
      100%   {
        background: url('assets/bg.jpg') fixed center no-repeat;
      }
    } */
    .text-link
    {
      color: #CE8610 !important;
      text-decoration: none;
    }
    .wrapper_bg
    {
      background-image: linear-gradient(rgba(0, 0, 0, 0), #fff, #fff, #fff);
    }
    .vh-100
    {
      min-height: 100vh !important;
      height: auto !important;
      max-height: auto !important;
    }
  
    /* headline */
    .headline_1{
      color: #07006F;
      font-family: Michroma;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
    }
    .headline_1 span 
    {
      background: linear-gradient(181deg, #A26501 26.11%, #EEBE3A 68.68%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Michroma;
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
    }
    @media only screen and (max-width: 600px) {
      .headline_1{
        font-size: 30px !important;
        font-weight: 400;
        line-height: 50px;
      }
      .headline_1 span 
      {
        font-size: 30px;
        font-weight: 400;
        line-height: 50px;
      }
    }
    /* headline */
  
    /* popup */
    .offcanvas.offcanvas-bottom
    {
      height: auto;
    }
    /* popup */
  
    /* custom card */
    .page_card
    {
      padding: 0 !important;
      margin: 0 !important;
      position:fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 100;
    }
    .page_card .card-header
    {
      background: url("../src/assets/bg-quran.png") center;
      background-size:contain;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .page_card .card-footer
    {
      background: url("../src/assets/bg (1).jpg") center;
      background-size:auto;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  
    .cover_card
    {
      border-radius: 6px;
      border: 1px solid #E3B232;
      background: #F8F8F8;
      box-shadow: 0px 4px 20px 0px rgba(233, 178, 28, 0.31);
      padding: 30px;
      position: relative;
    }
    .cover_card:hover
    {
      border-radius: 6px;
      border: 1px solid #CE8610;
      box-shadow: 0px 4px 20px 0px rgba(233, 178, 28, 0.31);
      padding: 30px;
      position: relative;
    }
    .cover_card::before
    {
      content: "";
      position: absolute;
      height: 100px;
      width: 100px;
      background: url("assets/top.svg") no-repeat right top;
      background-size: contain;
      top: 0;
      right: 0;
      display: block;
      z-index: 1 !important
  
    }
    .cover_card::after
    {
      content: "";
      position: absolute;
      height: 100px;
      width: 100px;
      background: url("assets/bottom.svg") no-repeat bottom left;
      background-size: contain;
      bottom: 0;
      left: 0;
      display: block;
      z-index: 1 !important
    }
    .cover_card .card-body
    {
      border: 1.5px solid #D3B358;
    }
    .card-number
    {
      width: 50px;
      height: 50px;
      font-size: 30px;
      font-style: normal;
      flex-shrink: 0;
      background: linear-gradient(181deg, #CE8610 2.46%, #D4A11D 53.41%, #F8BE1B 95.48%);
      display: block !important;
      margin: auto;
      line-height: 50px;
      color: white;
      border-radius: 50%;
      margin-bottom: 30px;
    }
    .card-number-2
    {
      width: 50px;
      height: 50px;
      font-size: 30px;
      font-style: normal;
      flex-shrink: 0;
      background: linear-gradient(181deg, #CE8610 2.46%, #D4A11D 53.41%, #F8BE1B 95.48%);
      display: block !important;
      margin: auto;
      line-height: 50px;
      color: white;
      border-radius: 50%;
    }
    @media only screen and (max-width: 600px) {
      .card-number
      {
        width: 50px;
        height: 50px;
        font-size: 20px !important;
      }
    }
    
    .cover_card h1{
      color: #353535;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 111.111% */
    }
  
    .cover_card h2{
      color: #898989;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .cover_card p{
      color: #BA9527;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
  
    @media only screen and (max-width: 600px) {
      .cover_card::before
      {
        content: "";
        position: absolute;
        height: 30px;
        width: 30px;
        background: url("assets/top.svg") no-repeat right top;
        background-size: contain;
        top: 0;
        right: 0;
        display: block;
        z-index: 1 !important
  
      }
      .cover_card::after
      {
        content: "";
        position: absolute;
        height: 30px;
        width: 30px;
        background: url("assets/bottom.svg") no-repeat bottom left;
        background-size: contain;
        bottom: 0;
        left: 0;
        display: block;
        z-index: 1 !important
      }
    }
  
    /* headline */
    .headline_1{
      color: #07006F;
      font-family: Michroma;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
    }
    .headline_1 span 
    {
      background: linear-gradient(181deg, #A26501 26.11%, #EEBE3A 68.68%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Michroma;
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
    }
    .headline_3{
      color: #07006F;
      font-family: arial;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
    }
    @media only screen and (max-width: 600px) {
      .headline_1{
        font-size: 30px !important;
        font-weight: 400;
        line-height: 50px;
      }
      .headline_1 span 
      {
        font-size: 30px;
        font-weight: 400;
        line-height: 50px;
      }
      .headline_3{
        color: #07006F;
        font-family: Michroma;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }
    /* headline */
    #searchWrapper
    {
      position: relative;
    }
  
    #searchResultsArea
    {
      height: 80vh;
      max-width: 80vw;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255,255,255,0.9);
      z-index: 100;
      border-radius: 10px !important;
      padding: 20px;
      position: fixed;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    @media only screen and (max-width: 600px) {
      #searchResultsArea
      {
        height: 100%;
        max-width: 100%;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        padding: 0px;
      }
    }
    #searchResultsArea .closeButton
    {
      width: 60px;
      height: 60px;
      line-height: 60px;
      flex-shrink: 0;
      border-radius: 50%;
      background: red;
      color: white;
      display: block;
      position: absolute;
      top: -30px;
      right: -30px;
    }
    
    #searchWrapper .search_input 
    {
      border-radius: 90px;
      border: 0.3px solid #EFBF3B;
      background: rgba(239, 191, 59, 0.8);
      box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
      padding: 0 30px;
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
    #searchWrapper .search_button 
    {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 50%;
      background: #A36501;
      color: white;
    }
  
    /* buttons */
    .play_button
    {
      border-radius: 12px;
      padding: 5px 19px 5px 20px;
      border: none;
      color: #805002;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      background: linear-gradient(180deg, #F4EDD9 0%, #F5D784 100%);
      transition-duration: 0.5s;
      box-shadow: 0px 0px 2px #999;
    }
    .play_button:focus {
      background: #CE8610;
      color: white;
    }
    .play_button:hover {
      background: linear-gradient(180deg, #F5D784 0%, #F4EDD9 100%);
      color: #805002;
    }
    .button
    {
      padding: 20px 100px;
      align-items: center;
      border-radius: 30px;
      border: 1px solid #EFBF3B;
      background: linear-gradient(180deg, #EFBF3B 0%, #F5D784 100%);
      text-decoration: none;
      color: #fff;
      font-weight: bold;
      letter-spacing: 1px;
      display: inline-block;
      cursor: pointer;
    }
    .button_square
    {
      padding: 0px !important;
      height: 40px !important;
      width: 40px !important;
      line-height: 30px !important;
      border-radius: 10px;
      font-size: 30px;
      display: block;
      text-align: center;
      margin: 0 !important;
    }
    .mobile_menu
    {
      border: 0;
      background: white;
      font-size: 30px;
      height: 50px;
      width: 50px;
      border-radius: 5px;
    }
    /* .button.button_outline
    {
      padding: 20px 100px;
      align-items: center;
      border-radius: 30px;
      border-radius: 30px;
      border: 1px solid #EFBF3B;
      background: white;
    } */
  
    .button.router-link-active
    {
      padding: 20px 100px;
      align-items: center;
      border-radius: 30px;
      border-radius: 30px;
      border: 1px solid #EFBF3B;
      background: white;
      color: #000;
    }
  
    .button:hover
    {
      padding: 20px 100px;
      align-items: center;
      border-radius: 30px;
      border-radius: 30px;
      border: 1px solid #EFBF3B;
      background: white;
      color: #000;
    }
    @media only screen and (max-width: 600px) {
      .button
      {
        padding: 5px 20px !important;
      }
      .button_square
      {
        padding: 0px !important;
        height: 40px !important;
        width: 40px !important;
        border-radius: 10px;
        font-size: 30px;
        display: block;
        text-align: center;
        margin: 0 !important;
      }
    }
    /* buttons */
  
  
    /* image */
    .max-width-100{
      max-width: 100%;
    }
  
    /* cards */
    .ayat_card{
      border-radius: 5px;
      background: white !important;
      border: 1px solid #D0BF98;
      /* padding: 50px 20px 10px 10px; */
      /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
      position: relative;
      margin-bottom: 30px;
    }
    .ayat_card .card-header
    {
      background:  transparent;
      background-size:contain;
      background-repeat: repeat-x;
      min-height: 30px;
      color: #a4551a;
      font-weight: bold;
    }
    .ayat_card hr 
    {
      border: 0;
      display: block;
      height: 5px;
      width: 100%;
      margin: 0;
      padding: 0;
      background: linear-gradient(90deg, #A36501 12.12%, #F5DE9D 100%);
    }
    .ayat_card .card-footer
    {
      background: url("../src/assets/ayath-card-footer.png") center transparent;
      background-size:auto;
      background-repeat: repeat-x;
      min-height: 30px;
      color: #a4551a;
      font-weight: bold;
    }
  
    .ayat_card .card-title
    {
      line-height: 30px !important;
    }
  
    @media only screen and (max-width: 600px) {
      .ayat_card .card-header
      {
        background: url("../src/assets/header-bg.png") bottom transparent;
        background-size:cover;
        color: #a4551a;
        font-weight: bold;
        font-size: 15px;
      }
  
      .ayat_card .card-title
      {
        line-height: 30px !important;
      }
    }
  
    /* .ayat_card::before{
      content: "";
      background: url("../src/assets/header-bg.png") center;
      background-size:contain;
      background-repeat: repeat-x;
      height: 30px;
      width: 100%;
      top: 0px;
      right: 0px;
      position: absolute;
    } */
    /* .ayat_card::after{
      content: "";
      background: url("../src/assets/footer-bg.png") center;
      background-size:contain;
      background-repeat: repeat-x;
      height: 50px;
      width: 100%;
      bottom: 0px;
      right: 0px;
      position: absolute;
    } */
  
  
  
  
  
    /* breadcrumb */
    .breadcrumb-item a{
      color: #CE8610;
      text-decoration: none;
    }
  
    .call_to_action
    {
        background: url("../src/assets/call-to-action-bg.png");
        background-size: cover;
    }
  
    .cusrsor-pointer
    {
      cursor: pointer;
    }
  
    .show {
      display: block !important;
    }
  
    .profile_offcanvas
    {
      position: fixed;
      height: 100vh;
      max-width: 400px;
      background: white;
      right: 0;
      top: 0;
      z-index: 100;
      display: flex;
      flex-direction: column;
    }
    .profile_offcanvas .header,
    .profile_offcanvas .body
    {
      width: 100%;
      padding: 20px;
    }
    .profile_offcanvas .body
    {
      height: 100%;
      overflow: auto;
    }
  </style>
  
  
  
  